@import 'tailwindcss/base';

@import 'tailwindcss/components';

@import 'tailwindcss/utilities';

// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {

  ion-content,
  ion-toolbar,
  ion-footer,
  ion-header {
    --background: var(--ion-color-page-background);
    --background: #ebedf0
  }


  /** primary **/

  --ion-color-primary: #194388;
  --ion-color-primary-rgb: 25,
  67,
  136;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255,
  255,
  255;
  --ion-color-primary-shade: #163b78;
  --ion-color-primary-tint: #305694;

  /** secondary **/
  --ion-color-secondary: #E3032C;
  --ion-color-secondary-rgb: 227,
  3,
  44;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255,
  255,
  255;
  --ion-color-secondary-shade: #c80327;
  --ion-color-secondary-tint: #e61c41;

  /** tertiary **/

  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82,
  96,
  255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255,
  255,
  255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/

  --ion-color-success: #00a75a;
  --ion-color-success-rgb: 0,
  167,
  90;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255,
  255,
  255;
  --ion-color-success-shade: #00934f;
  --ion-color-success-tint: #1ab06b;

  /** warning **/

  --ion-color-warning: #FFCC00;
  --ion-color-warning-rgb: 255,
  204,
  0;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0,
  0,
  0;
  --ion-color-warning-shade: #e0b400;
  --ion-color-warning-tint: #ffd11a;

  /** danger **/

  --ion-color-danger: #FF3B30;
  --ion-color-danger-rgb: 255,
  59,
  48;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255,
  255,
  255;
  --ion-color-danger-shade: #e0342a;
  --ion-color-danger-tint: #ff4f45;

  /** dark **/

  --ion-color-dark: #1C1C1E;
  --ion-color-dark-rgb: 28,
  28,
  30;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255,
  255,
  255;
  --ion-color-dark-shade: #19191a;
  --ion-color-dark-tint: #333335;

  /** grey **/
  --ion-color-grey: #d3d6db;
  --ion-color-grey-rgb: 182, 187, 194;

  /** Alice blue **/
  --ion-color-alice-blue: #f2f7fc;
  --ion-color-alice-blue-rgb: 225, 235, 252;

  /** medium **/

  --ion-color-medium: #75767d;
  --ion-color-medium-rgb: 117,
  118,
  125;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255,
  255,
  255;
  --ion-color-medium-shade: #67686e;
  --ion-color-medium-tint: #83848a;

  /** light **/

  --ion-color-light: #e9e9e9;
  --ion-color-light-rgb: 233,
  233,
  233;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0,
  0,
  0;
  --ion-color-light-shade: #cdcdcd;
  --ion-color-light-tint: #ebebeb;

  /** very light **/

  --ion-color-very-light: #f5f5f5;
  --ion-color-very-light-rgb: 245,
  245,
  245;
  --ion-color-very-light-contrast: #000000;
  --ion-color-very-light-contrast-rgb: 0,
  0,
  0;
  --ion-color-very-light-shade: #d8d8d8;
  --ion-color-very-light-tint: #f6f6f6;


  /** Hylo Purple **/
  --ion-color-purple: #7030A0;
  --ion-color-purple-rgb: 112,
  48,
  160;
  --ion-color-purple-contrast: #ffffff;
  --ion-color-purple-contrast-rgb: 255,
  255,
  255;
  --ion-color-purple-shade: #632a8d;
  --ion-color-purple-tint: #7e45aa;

  /** Hylo Mustard **/

  --ion-color-mustard: #F6C645;
  --ion-color-mustard-rgb: 246,
  198,
  69;
  --ion-color-mustard-contrast: #000000;
  --ion-color-mustard-contrast-rgb: 0,
  0,
  0;
  --ion-color-mustard-shade: #d8ae3d;
  --ion-color-mustard-tint: #f7cc58;

  /** White **/
  --ion-color-white: #ffffff;
  --ion-color-white-rgb: 255,
  255,
  255;
  --ion-color-white-contrast: #000000;
  --ion-color-white-contrast-rgb: 0,
  0,
  0;
  --ion-color-white-shade: #e0e0e0;
  --ion-color-white-tint: #ffffff;

  /** Black **/
  --ion-color-black: #000000;
  --ion-color-black-rgb: 0,
  0,
  0;
  --ion-color-black-contrast: #ffffff;
  --ion-color-black-contrast-rgb: 255,
  255,
  255;
  --ion-color-black-shade: #000000;
  --ion-color-black-tint: #1a1a1a;

  /** Blue **/
  --ion-color-blue: #0073b7;
  --ion-color-blue-rgb: 0,
  115,
  183;
  --ion-color-blue-contrast: #ffffff;
  --ion-color-blue-contrast-rgb: 255,
  255,
  255;
  --ion-color-blue-shade: #0065a1;
  --ion-color-blue-tint: #1a81be;

  /** facebook **/
  --ion-color-facebook: #3b5998;
  --ion-color-facebook-rgb: 59,
  89,
  152;
  --ion-color-facebook-contrast: #ffffff;
  --ion-color-facebook-contrast-rgb: 255,
  255,
  255;
  --ion-color-facebook-shade: #344e86;
  --ion-color-facebook-tint: #4f6aa2;

  /** indigo **/
  --ion-color-indigo: #3d0f9f;
  --ion-color-indigo-rgb: 61,
  15,
  159;
  --ion-color-indigo-contrast: #ffffff;
  --ion-color-indigo-contrast-rgb: 255,
  255,
  255;
  --ion-color-indigo-shade: #360d8c;
  --ion-color-indigo-tint: #5027a9;

  /** fusion theme **/

  --ion-color-fusion-theme: #f9f9fe;
  --ion-color-fusion-theme-rgb: 249,
  249,
  254;
  --ion-color-fusion-theme-contrast: #000000;
  --ion-color-fusion-theme-contrast-rgb: 0,
  0,
  0;
  --ion-color-fusion-theme-shade: #dbdbe0;
  --ion-color-fusion-theme-tint: #fafafe;

  /** receivables card theme **/
  --ion-color-receivables-card: #059669;
  --ion-color-receivables-card-rgb: 5,
  150,
  105;
  --ion-color-receivables-card-contrast: #ffffff;
  --ion-color-receivables-card-contrast-rgb: 255,
  255,
  255;
  --ion-color-receivables-card-shade: #04845c;
  --ion-color-receivables-card-tint: #1ea178;

  /** payables card theme **/
  --ion-color-payables-card: #dc2626;
  --ion-color-payables-card-rgb: 220,
  38,
  38;
  --ion-color-payables-card-contrast: #ffffff;
  --ion-color-payables-card-contrast-rgb: 255,
  255,
  255;
  --ion-color-payables-card-shade: #c22121;
  --ion-color-payables-card-tint: #e03c3c;

  /** Pending Badges **/

  --ion-color-pending: #ebeaf6;
  --ion-color-pending-rgb: 235,
  234,
  246;
  --ion-color-pending-contrast: #362da2;
  --ion-color-pending-contrast-rgb: 54,
  45,
  162;
  --ion-color-pending-shade: #cfced8;
  --ion-color-pending-tint: #edecf7;

  /** Partial Paid Badges **/

  --ion-color-partial: #fff5cc;
  --ion-color-partial-rgb: 255,
  245,
  204;
  --ion-color-partial-contrast: #d8ae3d;
  --ion-color-partial-contrast-rgb: 0,
  0,
  0;
  --ion-color-partial-shade: #e0d8b4;
  --ion-color-partial-tint: #fff6d1;

  /** Paid Badges **/

  --ion-color-paid: #e6f6ef;
  --ion-color-paid-rgb: 230,
  246,
  239;
  --ion-color-paid-contrast: #00a75a;
  --ion-color-paid-contrast-rgb: 5,
  150,
  105;
  --ion-color-paid-shade: #cad8d2;
  --ion-color-paid-tint: #e9f7f1;

  /** Failed Badges **/

  --ion-color-failed: #fce9e9;
  --ion-color-failed-rgb: 252,
  233,
  233;
  --ion-color-failed-contrast: #dc2626;
  --ion-color-failed-contrast-rgb: 220,
  38,
  38;
  --ion-color-failed-shade: #decdcd;
  --ion-color-failed-tint: #fcebeb;

  /** Draft Badges **/

  --ion-color-draft: #e3e4e5;
  --ion-color-draft-rgb: 227,
  228,
  229;
  --ion-color-draft-contrast: #000000;
  --ion-color-draft-contrast-rgb: 0,
  0,
  0;
  --ion-color-draft-shade: #c8c9ca;
  --ion-color-draft-tint: #e6e7e8;

  /** Page Background **/
  --ion-color-page-background: #ebedf0;
  --ion-color-page-background-rgb: 235, 237, 240;
  --ion-color-page-background-contrast: #000000;
  --ion-color-page-background-contrast-rgb: 0,
  0,
  0;
  --ion-color-page-background-shade: #dfdfdf;
  --ion-color-page-background-tint: #fdfdfd;

  --ion-color-minty-green: #8AAE92;
  --ion-color-minty-green-rgb: 138,
  174,
  146;
  --ion-color-minty-green-contrast: #000000;
  --ion-color-minty-green-contrast-rgb: 0,
  0,
  0;
  --ion-color-minty-green-shade: #799980;
  --ion-color-minty-green-tint: #96b69d;

  --ion-color-minty-green-800: #e8efe9;
  --ion-color-minty-green-800-rgb: 232,
  239,
  233;
  --ion-color-minty-green-800-contrast: #000000;
  --ion-color-minty-green-800-contrast-rgb: 0,
  0,
  0;
  --ion-color-minty-green-800-shade: #ccd2cd;
  --ion-color-minty-green-800-tint: #eaf1eb;

  --ion-color-input-box: #f9f9f9;
  --ion-color-input-box-rgb: 249,
  249,
  249;
  --ion-color-input-box-contrast: #000000;
  --ion-color-input-box-contrast-rgb: 0,
  0,
  0;
  --ion-color-input-box-shade: #dbdbdb;
  --ion-color-input-box-tint: #fafafa;

}


/** Custom CSS for Hylo App **/

.ion-color-purple {
  --ion-color-base: var(--ion-color-purple);
  --ion-color-base-rgb: var(--ion-color-purple-rgb);
  --ion-color-contrast: var(--ion-color-purple-contrast);
  --ion-color-contrast-rgb: var(--ion-color-purple-contrast-rgb);
  --ion-color-shade: var(--ion-color-purple-shade);
  --ion-color-tint: var(--ion-color-purple-tint);
}

.ion-color-mustard {
  --ion-color-base: var(--ion-color-mustard);
  --ion-color-base-rgb: var(--ion-color-mustard-rgb);
  --ion-color-contrast: var(--ion-color-mustard-contrast);
  --ion-color-contrast-rgb: var(--ion-color-mustard-contrast-rgb);
  --ion-color-shade: var(--ion-color-mustard-shade);
  --ion-color-tint: var(--ion-color-mustard-tint);
}

.ion-color-white {
  --ion-color-base: var(--ion-color-white);
  --ion-color-base-rgb: var(--ion-color-white-rgb);
  --ion-color-contrast: var(--ion-color-white-contrast);
  --ion-color-contrast-rgb: var(--ion-color-white-contrast-rgb);
  --ion-color-shade: var(--ion-color-white-shade);
  --ion-color-tint: var(--ion-color-white-tint);
}

.ion-color-danger {
  --ion-color-base: var(--ion-color-danger);
  --ion-color-base-rgb: var(--ion-color-danger-rgb);
  --ion-color-contrast: var(--ion-color-danger-contrast);
  --ion-color-contrast-rgb: var(--ion-color-danger-contrast-rgb);
  --ion-color-shade: var(--ion-color-danger-shade);
  --ion-color-tint: var(--ion-color-danger-tint);
}

.ion-color-blue {
  --ion-color-base: var(--ion-color-blue);
  --ion-color-base-rgb: var(--ion-color-blue-rgb);
  --ion-color-contrast: var(--ion-color-blue-contrast);
  --ion-color-contrast-rgb: var(--ion-color-blue-contrast-rgb);
  --ion-color-shade: var(--ion-color-blue-shade);
  --ion-color-tint: var(--ion-color-blue-tint);
}

.ion-color-pending {
  --ion-color-base: var(--ion-color-pending);
  --ion-color-base-rgb: var(--ion-color-pending-rgb);
  --ion-color-contrast: var(--ion-color-pending-contrast);
  --ion-color-contrast-rgb: var(--ion-color-pending-contrast-rgb);
  --ion-color-shade: var(--ion-color-pending-shade);
  --ion-color-tint: var(--ion-color-pending-tint);
}

.ion-color-facebook {
  --ion-color-base: var(--ion-color-facebook);
  --ion-color-base-rgb: var(--ion-color-facebook-rgb);
  --ion-color-contrast: var(--ion-color-facebook-contrast);
  --ion-color-contrast-rgb: var(--ion-color-facebook-contrast-rgb);
  --ion-color-shade: var(--ion-color-facebook-shade);
  --ion-color-tint: var(--ion-color-facebook-tint);
}

.ion-color-indigo {
  --ion-color-base: var(--ion-color-indigo);
  --ion-color-base-rgb: var(--ion-color-indigo-rgb);
  --ion-color-contrast: var(--ion-color-indigo-contrast);
  --ion-color-contrast-rgb: var(--ion-color-indigo-contrast-rgb);
  --ion-color-shade: var(--ion-color-indigo-shade);
  --ion-color-tint: var(--ion-color-indigo-tint);

}

.ion-color-fusion-theme {
  --ion-color-base: var(--ion-color-fusion-theme);
  --ion-color-base-rgb: var(--ion-color-fusion-theme-rgb);
  --ion-color-contrast: var(--ion-color-fusion-theme-contrast);
  --ion-color-contrast-rgb: var(--ion-color-fusion-theme-contrast-rgb);
  --ion-color-shade: var(--ion-color-fusion-theme-shade);
  --ion-color-tint: var(--ion-color-fusion-theme-tint);
}

.ion-color-receivables-card {
  --ion-color-base: var(--ion-color-receivables-card);
  --ion-color-base-rgb: var(--ion-color-receivables-card-rgb);
  --ion-color-contrast: var(--ion-color-receivables-card-contrast);
  --ion-color-contrast-rgb: var(--ion-color-receivables-card-contrast-rgb);
  --ion-color-shade: var(--ion-color-receivables-card-shade);
  --ion-color-tint: var(--ion-color-receivables-card-tint);
}

.ion-color-payables-card {
  --ion-color-base: var(--ion-color-payables-card);
  --ion-color-base-rgb: var(--ion-color-payables-card-rgb);
  --ion-color-contrast: var(--ion-color-payables-card-contrast);
  --ion-color-contrast-rgb: var(--ion-color-payables-card-contrast-rgb);
  --ion-color-shade: var(--ion-color-payables-card-shade);
  --ion-color-tint: var(--ion-color-payables-card-tint);
}

.ion-color-very-light {
  --ion-color-base: var(--ion-color-very-light);
  --ion-color-base-rgb: var(--ion-color-very-light-rgb);
  --ion-color-contrast: var(--ion-color-very-light-contrast);
  --ion-color-contrast-rgb: var(--ion-color-very-light-contrast-rgb);
  --ion-color-shade: var(--ion-color-very-light-shade);
  --ion-color-tint: var(--ion-color-very-light-tint);
}

.ion-color-black {
  --ion-color-base: var(--ion-color-black);
  --ion-color-base-rgb: var(--ion-color-black-rgb);
  --ion-color-contrast: var(--ion-color-black-contrast);
  --ion-color-contrast-rgb: var(--ion-color-black-contrast-rgb);
  --ion-color-shade: var(--ion-color-black-shade);
  --ion-color-tint: var(--ion-color-black-tint);
}

.ion-color-pending {
  --ion-color-base: var(--ion-color-pending);
  --ion-color-base-rgb: var(--ion-color-pending-rgb);
  --ion-color-contrast: var(--ion-color-pending-contrast);
  --ion-color-contrast-rgb: var(--ion-color-pending-contrast-rgb);
  --ion-color-shade: var(--ion-color-pending-shade);
  --ion-color-tint: var(--ion-color-pending-tint);
}

.ion-color-partial {
  --ion-color-base: var(--ion-color-partial);
  --ion-color-base-rgb: var(--ion-color-partial-rgb);
  --ion-color-contrast: var(--ion-color-partial-contrast);
  --ion-color-contrast-rgb: var(--ion-color-partial-contrast-rgb);
  --ion-color-shade: var(--ion-color-partial-shade);
  --ion-color-tint: var(--ion-color-partial-tint);
}

.ion-color-paid {
  --ion-color-base: var(--ion-color-paid);
  --ion-color-base-rgb: var(--ion-color-paid-rgb);
  --ion-color-contrast: var(--ion-color-paid-contrast);
  --ion-color-contrast-rgb: var(--ion-color-paid-contrast-rgb);
  --ion-color-shade: var(--ion-color-paid-shade);
  --ion-color-tint: var(--ion-color-paid-tint);
}

.ion-color-failed {
  --ion-color-base: var(--ion-color-failed);
  --ion-color-base-rgb: var(--ion-color-failed-rgb);
  --ion-color-contrast: var(--ion-color-failed-contrast);
  --ion-color-contrast-rgb: var(--ion-color-failed-contrast-rgb);
  --ion-color-shade: var(--ion-color-failed-shade);
  --ion-color-tint: var(--ion-color-failed-tint);
}

.ion-color-draft {
  --ion-color-base: var(--ion-color-draft);
  --ion-color-base-rgb: var(--ion-color-draft-rgb);
  --ion-color-contrast: var(--ion-color-draft-contrast);
  --ion-color-contrast-rgb: var(--ion-color-draft-contrast-rgb);
  --ion-color-shade: var(--ion-color-draft-shade);
  --ion-color-tint: var(--ion-color-draft-tint);
}

.ion-color-page-background {
  --ion-color-base: var(--ion-color-page-background);
  --ion-color-base-rgb: var(--ion-color-page-background-rgb);
  --ion-color-contrast: var(--ion-color-page-background-contrast);
  --ion-color-contrast-rgb: var(--ion-color-page-background-contrast-rgb);
  --ion-color-shade: var(--ion-color-page-background-shade);
  --ion-color-tint: var(--ion-color-page-background-tint);
}

.ion-color-minty-green {
  --ion-color-base: var(--ion-color-minty-green);
  --ion-color-base-rgb: var(--ion-color-minty-green-rgb);
  --ion-color-contrast: var(--ion-color-minty-green-contrast);
  --ion-color-contrast-rgb: var(--ion-color-minty-green-contrast-rgb);
  --ion-color-shade: var(--ion-color-minty-green-shade);
  --ion-color-tint: var(--ion-color-minty-green-tint);
}

.ion-color-minty-green-800 {
  --ion-color-base: var(--ion-color-minty-green-800);
  --ion-color-base-rgb: var(--ion-color-minty-green-800-rgb);
  --ion-color-contrast: var(--ion-color-minty-green-800-contrast);
  --ion-color-contrast-rgb: var(--ion-color-minty-green-800-contrast-rgb);
  --ion-color-shade: var(--ion-color-minty-green-800-shade);
  --ion-color-tint: var(--ion-color-minty-green-800-tint);
}

.ion-color-input-box {
  --ion-color-base: var(--ion-color-input-box);
  --ion-color-base-rgb: var(--ion-color-input-box-rgb);
  --ion-color-contrast: var(--ion-color-input-box-contrast);
  --ion-color-contrast-rgb: var(--ion-color-input-box-contrast-rgb);
  --ion-color-shade: var(--ion-color-input-box-shade);
  --ion-color-tint: var(--ion-color-input-box-tint);
}

.ion-color-alice-blue {
  --ion-color-alice-blue: var(--ion-color-alice-blue);
}


.hide {
  display: none !important
}

.no-border {
  border: 0 !important
}

.no-padding {
  padding: 0 !important
}

.no-margin {
  margin: 0 !important
}

.no-shadow {
  box-shadow: none !important
}

.m-0 {
  margin: 0 !important
}

.mt-0,
.my-0 {
  margin-top: 0 !important
}

.mr-0,
.mx-0 {
  margin-right: 0 !important
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important
}

.ml-0,
.mx-0 {
  margin-left: 0 !important
}

.m-1 {
  margin: .25rem !important
}

.mt-1,
.my-1 {
  margin-top: .25rem !important
}

.mr-1,
.mx-1 {
  margin-right: .25rem !important
}

.mb-1,
.my-1 {
  margin-bottom: .25rem !important
}

.ml-1,
.mx-1 {
  margin-left: .25rem !important
}

.m-2 {
  margin: .5rem !important
}

.mt-2,
.my-2 {
  margin-top: .5rem !important
}

.mr-2,
.mx-2 {
  margin-right: .5rem !important
}

.mb-2,
.my-2 {
  margin-bottom: .5rem !important
}

.ml-2,
.mx-2 {
  margin-left: .5rem !important
}

.m-3 {
  margin: 1rem !important
}

.mt-3,
.my-3 {
  margin-top: 1rem !important
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important
}

.m-3-5 {
  margin: 1.25rem !important
}

.mt-3-5,
.my-3-5 {
  margin-top: 1.25rem !important
}

.mr-3-5,
.mx-3-5 {
  margin-right: 1.25rem !important
}

.mb-3-5,
.my-3-5 {
  margin-bottom: 1.25rem !important
}

.ml-3-5,
.mx-3-5 {
  margin-left: 1.25rem !important
}

.m-4 {
  margin: 1.5rem !important
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important
}

.m-4-5 {
  margin: 1.5rem !important
}

.mt-4-5,
.my-4-5 {
  margin-top: 2rem !important
}

.mr-4-5,
.mx-4-5 {
  margin-right: 2rem !important
}

.mb-4-5,
.my-4-5 {
  margin-bottom: 2rem !important
}

.ml-4-5,
.mx-4-5 {
  margin-left: 2rem !important
}


.m-5 {
  margin: 3rem !important
}

.mt-5,
.my-5 {
  margin-top: 3rem !important
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important
}

.p-0 {
  padding: 0 !important
}

.pt-0,
.py-0 {
  padding-top: 0 !important
}

.pr-0,
.px-0 {
  padding-right: 0 !important
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important
}

.pl-0,
.px-0 {
  padding-left: 0 !important
}

.p-1 {
  padding: .25rem !important
}

.pt-1,
.py-1 {
  padding-top: .25rem !important
}

.pr-1,
.px-1 {
  padding-right: .25rem !important
}

.pb-1,
.py-1 {
  padding-bottom: .25rem !important
}

.pl-1,
.px-1 {
  padding-left: .25rem !important
}

.p-2 {
  padding: .5rem !important
}

.pt-2,
.py-2 {
  padding-top: .5rem !important
}

.pr-2,
.px-2 {
  padding-right: .5rem !important
}

.pb-2,
.py-2 {
  padding-bottom: .5rem !important
}

.pl-2,
.px-2 {
  padding-left: .5rem !important
}

.p-3 {
  padding: 1rem !important
}

.pt-3,
.py-3 {
  padding-top: 1rem !important
}

.pr-3,
.px-3 {
  padding-right: 1rem !important
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important
}

.pl-3,
.px-3 {
  padding-left: 1rem !important
}

.p-3-5 {
  padding: 1.25rem !important
}

.pt-3-5,
.py-3-5 {
  padding-top: 1.35rem !important
}

.pr-3-5,
.px-3-5 {
  padding-right: 1.35rem !important
}

.pb-3-5,
.py-3-5 {
  padding-bottom: 1.35rem !important
}

.pl-3-5,
.px-3-5 {
  padding-left: 1.35rem !important
}


.p-4 {
  padding: 1.5rem !important
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important
}

.p-4-5 {
  padding: 2rem !important
}

.pt-4-5,
.py-4-5 {
  padding-top: 2rem !important
}

.pr-4-5,
.px-4-5 {
  padding-right: 2rem !important
}

.pb-4-5,
.py-4-5 {
  padding-bottom: 2rem !important
}

.pl-4-5,
.px-4-5 {
  padding-left: 2rem !important
}

.p-5 {
  padding: 3rem !important
}

.pt-5,
.py-5 {
  padding-top: 3rem !important
}

.pr-5,
.px-5 {
  padding-right: 3rem !important
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important
}

.pl-5,
.px-5 {
  padding-left: 3rem !important
}

.m-n1 {
  margin: -.25rem !important
}

.mt-n1,
.my-n1 {
  margin-top: -.25rem !important
}

.mr-n1,
.mx-n1 {
  margin-right: -.25rem !important
}

.mb-n1,
.my-n1 {
  margin-bottom: -.25rem !important
}

.ml-n1,
.mx-n1 {
  margin-left: -.25rem !important
}

.m-n2 {
  margin: -.5rem !important
}

.mt-n2,
.my-n2 {
  margin-top: -.5rem !important
}

.mr-n2,
.mx-n2 {
  margin-right: -.5rem !important
}

.mb-n2,
.my-n2 {
  margin-bottom: -.5rem !important
}

.ml-n2,
.mx-n2 {
  margin-left: -.5rem !important
}

.m-n3 {
  margin: -1rem !important
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important
}

.m-n4 {
  margin: -1.5rem !important
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important
}

.m-n5 {
  margin: -3rem !important
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important
}

.m-auto {
  margin: auto !important
}

.mt-auto,
.my-auto {
  margin-top: auto !important
}

.mr-auto,
.mx-auto {
  margin-right: auto !important
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important
}

.ml-auto,
.mx-auto {
  margin-left: auto !important
}

@media (min-width:576px) {
  .m-sm-0 {
    margin: 0 !important
  }

  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important
  }

  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important
  }

  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important
  }

  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important
  }

  .m-sm-1 {
    margin: .25rem !important
  }

  .mt-sm-1,
  .my-sm-1 {
    margin-top: .25rem !important
  }

  .mr-sm-1,
  .mx-sm-1 {
    margin-right: .25rem !important
  }

  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: .25rem !important
  }

  .ml-sm-1,
  .mx-sm-1 {
    margin-left: .25rem !important
  }

  .m-sm-2 {
    margin: .5rem !important
  }

  .mt-sm-2,
  .my-sm-2 {
    margin-top: .5rem !important
  }

  .mr-sm-2,
  .mx-sm-2 {
    margin-right: .5rem !important
  }

  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: .5rem !important
  }

  .ml-sm-2,
  .mx-sm-2 {
    margin-left: .5rem !important
  }

  .m-sm-3 {
    margin: 1rem !important
  }

  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important
  }

  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important
  }

  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important
  }

  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important
  }

  .m-sm-4 {
    margin: 1.5rem !important
  }

  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important
  }

  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important
  }

  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important
  }

  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important
  }

  .m-sm-5 {
    margin: 3rem !important
  }

  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important
  }

  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important
  }

  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important
  }

  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important
  }

  .p-sm-0 {
    padding: 0 !important
  }

  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important
  }

  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important
  }

  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important
  }

  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important
  }

  .p-sm-1 {
    padding: .25rem !important
  }

  .pt-sm-1,
  .py-sm-1 {
    padding-top: .25rem !important
  }

  .pr-sm-1,
  .px-sm-1 {
    padding-right: .25rem !important
  }

  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: .25rem !important
  }

  .pl-sm-1,
  .px-sm-1 {
    padding-left: .25rem !important
  }

  .p-sm-2 {
    padding: .5rem !important
  }

  .pt-sm-2,
  .py-sm-2 {
    padding-top: .5rem !important
  }

  .pr-sm-2,
  .px-sm-2 {
    padding-right: .5rem !important
  }

  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: .5rem !important
  }

  .pl-sm-2,
  .px-sm-2 {
    padding-left: .5rem !important
  }

  .p-sm-3 {
    padding: 1rem !important
  }

  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important
  }

  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important
  }

  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important
  }

  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important
  }

  .p-sm-4 {
    padding: 1.5rem !important
  }

  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important
  }

  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important
  }

  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important
  }

  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important
  }

  .p-sm-5 {
    padding: 3rem !important
  }

  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important
  }

  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important
  }

  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important
  }

  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important
  }

  .m-sm-n1 {
    margin: -.25rem !important
  }

  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -.25rem !important
  }

  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -.25rem !important
  }

  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -.25rem !important
  }

  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -.25rem !important
  }

  .m-sm-n2 {
    margin: -.5rem !important
  }

  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -.5rem !important
  }

  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -.5rem !important
  }

  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -.5rem !important
  }

  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -.5rem !important
  }

  .m-sm-n3 {
    margin: -1rem !important
  }

  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important
  }

  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important
  }

  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important
  }

  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important
  }

  .m-sm-n4 {
    margin: -1.5rem !important
  }

  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important
  }

  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important
  }

  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important
  }

  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important
  }

  .m-sm-n5 {
    margin: -3rem !important
  }

  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important
  }

  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important
  }

  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important
  }

  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important
  }

  .m-sm-auto {
    margin: auto !important
  }

  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important
  }

  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important
  }

  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important
  }

  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important
  }
}

@media (min-width:768px) {
  .m-md-0 {
    margin: 0 !important
  }

  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important
  }

  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important
  }

  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important
  }

  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important
  }

  .m-md-1 {
    margin: .25rem !important
  }

  .mt-md-1,
  .my-md-1 {
    margin-top: .25rem !important
  }

  .mr-md-1,
  .mx-md-1 {
    margin-right: .25rem !important
  }

  .mb-md-1,
  .my-md-1 {
    margin-bottom: .25rem !important
  }

  .ml-md-1,
  .mx-md-1 {
    margin-left: .25rem !important
  }

  .m-md-2 {
    margin: .5rem !important
  }

  .mt-md-2,
  .my-md-2 {
    margin-top: .5rem !important
  }

  .mr-md-2,
  .mx-md-2 {
    margin-right: .5rem !important
  }

  .mb-md-2,
  .my-md-2 {
    margin-bottom: .5rem !important
  }

  .ml-md-2,
  .mx-md-2 {
    margin-left: .5rem !important
  }

  .m-md-3 {
    margin: 1rem !important
  }

  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important
  }

  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important
  }

  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important
  }

  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important
  }

  .m-md-4 {
    margin: 1.5rem !important
  }

  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important
  }

  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important
  }

  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important
  }

  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important
  }

  .m-md-5 {
    margin: 3rem !important
  }

  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important
  }

  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important
  }

  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important
  }

  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important
  }

  .p-md-0 {
    padding: 0 !important
  }

  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important
  }

  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important
  }

  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important
  }

  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important
  }

  .p-md-1 {
    padding: .25rem !important
  }

  .pt-md-1,
  .py-md-1 {
    padding-top: .25rem !important
  }

  .pr-md-1,
  .px-md-1 {
    padding-right: .25rem !important
  }

  .pb-md-1,
  .py-md-1 {
    padding-bottom: .25rem !important
  }

  .pl-md-1,
  .px-md-1 {
    padding-left: .25rem !important
  }

  .p-md-2 {
    padding: .5rem !important
  }

  .pt-md-2,
  .py-md-2 {
    padding-top: .5rem !important
  }

  .pr-md-2,
  .px-md-2 {
    padding-right: .5rem !important
  }

  .pb-md-2,
  .py-md-2 {
    padding-bottom: .5rem !important
  }

  .pl-md-2,
  .px-md-2 {
    padding-left: .5rem !important
  }

  .p-md-3 {
    padding: 1rem !important
  }

  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important
  }

  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important
  }

  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important
  }

  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important
  }

  .p-md-4 {
    padding: 1.5rem !important
  }

  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important
  }

  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important
  }

  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important
  }

  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important
  }

  .p-md-5 {
    padding: 3rem !important
  }

  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important
  }

  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important
  }

  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important
  }

  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important
  }

  .m-md-n1 {
    margin: -.25rem !important
  }

  .mt-md-n1,
  .my-md-n1 {
    margin-top: -.25rem !important
  }

  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -.25rem !important
  }

  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -.25rem !important
  }

  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -.25rem !important
  }

  .m-md-n2 {
    margin: -.5rem !important
  }

  .mt-md-n2,
  .my-md-n2 {
    margin-top: -.5rem !important
  }

  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -.5rem !important
  }

  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -.5rem !important
  }

  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -.5rem !important
  }

  .m-md-n3 {
    margin: -1rem !important
  }

  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important
  }

  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important
  }

  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important
  }

  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important
  }

  .m-md-n4 {
    margin: -1.5rem !important
  }

  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important
  }

  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important
  }

  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important
  }

  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important
  }

  .m-md-n5 {
    margin: -3rem !important
  }

  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important
  }

  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important
  }

  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important
  }

  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important
  }

  .m-md-auto {
    margin: auto !important
  }

  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important
  }

  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important
  }

  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important
  }

  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important
  }
}

@media (min-width:992px) {
  .m-lg-0 {
    margin: 0 !important
  }

  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important
  }

  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important
  }

  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important
  }

  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important
  }

  .m-lg-1 {
    margin: .25rem !important
  }

  .mt-lg-1,
  .my-lg-1 {
    margin-top: .25rem !important
  }

  .mr-lg-1,
  .mx-lg-1 {
    margin-right: .25rem !important
  }

  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: .25rem !important
  }

  .ml-lg-1,
  .mx-lg-1 {
    margin-left: .25rem !important
  }

  .m-lg-2 {
    margin: .5rem !important
  }

  .mt-lg-2,
  .my-lg-2 {
    margin-top: .5rem !important
  }

  .mr-lg-2,
  .mx-lg-2 {
    margin-right: .5rem !important
  }

  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: .5rem !important
  }

  .ml-lg-2,
  .mx-lg-2 {
    margin-left: .5rem !important
  }

  .m-lg-3 {
    margin: 1rem !important
  }

  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important
  }

  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important
  }

  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important
  }

  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important
  }

  .m-lg-4 {
    margin: 1.5rem !important
  }

  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important
  }

  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important
  }

  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important
  }

  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important
  }

  .m-lg-5 {
    margin: 3rem !important
  }

  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important
  }

  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important
  }

  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important
  }

  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important
  }

  .p-lg-0 {
    padding: 0 !important
  }

  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important
  }

  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important
  }

  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important
  }

  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important
  }

  .p-lg-1 {
    padding: .25rem !important
  }

  .pt-lg-1,
  .py-lg-1 {
    padding-top: .25rem !important
  }

  .pr-lg-1,
  .px-lg-1 {
    padding-right: .25rem !important
  }

  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: .25rem !important
  }

  .pl-lg-1,
  .px-lg-1 {
    padding-left: .25rem !important
  }

  .p-lg-2 {
    padding: .5rem !important
  }

  .pt-lg-2,
  .py-lg-2 {
    padding-top: .5rem !important
  }

  .pr-lg-2,
  .px-lg-2 {
    padding-right: .5rem !important
  }

  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: .5rem !important
  }

  .pl-lg-2,
  .px-lg-2 {
    padding-left: .5rem !important
  }

  .p-lg-3 {
    padding: 1rem !important
  }

  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important
  }

  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important
  }

  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important
  }

  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important
  }

  .p-lg-4 {
    padding: 1.5rem !important
  }

  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important
  }

  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important
  }

  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important
  }

  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important
  }

  .p-lg-5 {
    padding: 3rem !important
  }

  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important
  }

  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important
  }

  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important
  }

  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important
  }

  .m-lg-n1 {
    margin: -.25rem !important
  }

  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -.25rem !important
  }

  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -.25rem !important
  }

  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -.25rem !important
  }

  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -.25rem !important
  }

  .m-lg-n2 {
    margin: -.5rem !important
  }

  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -.5rem !important
  }

  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -.5rem !important
  }

  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -.5rem !important
  }

  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -.5rem !important
  }

  .m-lg-n3 {
    margin: -1rem !important
  }

  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important
  }

  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important
  }

  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important
  }

  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important
  }

  .m-lg-n4 {
    margin: -1.5rem !important
  }

  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important
  }

  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important
  }

  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important
  }

  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important
  }

  .m-lg-n5 {
    margin: -3rem !important
  }

  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important
  }

  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important
  }

  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important
  }

  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important
  }

  .m-lg-auto {
    margin: auto !important
  }

  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important
  }

  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important
  }

  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important
  }

  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important
  }
}

@media (min-width:1200px) {
  .m-xl-0 {
    margin: 0 !important
  }

  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important
  }

  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important
  }

  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important
  }

  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important
  }

  .m-xl-1 {
    margin: .25rem !important
  }

  .mt-xl-1,
  .my-xl-1 {
    margin-top: .25rem !important
  }

  .mr-xl-1,
  .mx-xl-1 {
    margin-right: .25rem !important
  }

  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: .25rem !important
  }

  .ml-xl-1,
  .mx-xl-1 {
    margin-left: .25rem !important
  }

  .m-xl-2 {
    margin: .5rem !important
  }

  .mt-xl-2,
  .my-xl-2 {
    margin-top: .5rem !important
  }

  .mr-xl-2,
  .mx-xl-2 {
    margin-right: .5rem !important
  }

  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: .5rem !important
  }

  .ml-xl-2,
  .mx-xl-2 {
    margin-left: .5rem !important
  }

  .m-xl-3 {
    margin: 1rem !important
  }

  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important
  }

  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important
  }

  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important
  }

  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important
  }

  .m-xl-4 {
    margin: 1.5rem !important
  }

  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important
  }

  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important
  }

  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important
  }

  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important
  }

  .m-xl-5 {
    margin: 3rem !important
  }

  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important
  }

  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important
  }

  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important
  }

  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important
  }

  .p-xl-0 {
    padding: 0 !important
  }

  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important
  }

  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important
  }

  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important
  }

  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important
  }

  .p-xl-1 {
    padding: .25rem !important
  }

  .pt-xl-1,
  .py-xl-1 {
    padding-top: .25rem !important
  }

  .pr-xl-1,
  .px-xl-1 {
    padding-right: .25rem !important
  }

  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: .25rem !important
  }

  .pl-xl-1,
  .px-xl-1 {
    padding-left: .25rem !important
  }

  .p-xl-2 {
    padding: .5rem !important
  }

  .pt-xl-2,
  .py-xl-2 {
    padding-top: .5rem !important
  }

  .pr-xl-2,
  .px-xl-2 {
    padding-right: .5rem !important
  }

  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: .5rem !important
  }

  .pl-xl-2,
  .px-xl-2 {
    padding-left: .5rem !important
  }

  .p-xl-3 {
    padding: 1rem !important
  }

  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important
  }

  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important
  }

  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important
  }

  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important
  }

  .p-xl-4 {
    padding: 1.5rem !important
  }

  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important
  }

  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important
  }

  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important
  }

  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important
  }

  .p-xl-5 {
    padding: 3rem !important
  }

  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important
  }

  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important
  }

  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important
  }

  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important
  }

  .m-xl-n1 {
    margin: -.25rem !important
  }

  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -.25rem !important
  }

  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -.25rem !important
  }

  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -.25rem !important
  }

  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -.25rem !important
  }

  .m-xl-n2 {
    margin: -.5rem !important
  }

  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -.5rem !important
  }

  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -.5rem !important
  }

  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -.5rem !important
  }

  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -.5rem !important
  }

  .m-xl-n3 {
    margin: -1rem !important
  }

  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important
  }

  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important
  }

  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important
  }

  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important
  }

  .m-xl-n4 {
    margin: -1.5rem !important
  }

  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important
  }

  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important
  }

  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important
  }

  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important
  }

  .m-xl-n5 {
    margin: -3rem !important
  }

  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important
  }

  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important
  }

  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important
  }

  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important
  }

  .m-xl-auto {
    margin: auto !important
  }

  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important
  }

  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important
  }

  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important
  }

  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important
  }
}

.bg-white {
  background-color: var(--ion-color-white) !important;
}

.bg-white-light-dark {
  background-color: var(--ion-color-fusion-theme) !important;
}

.bg-transparent {
  background: transparent !important;
  background-color: transparent !important;
  background-color: none !important;
}

.border {
  border: 1px solid var(--ion-color-medium);
}

.border-top {
  border-top: 1px solid var(--ion-color-medium);
}

.border-right {
  border-right: 1px solid var(--ion-color-medium);
}

.border-bottom {
  border-bottom: 1px solid var(--ion-color-medium);
}

.border-left {
  border-left: 1px solid var(--ion-color-medium);
}

.border-0 {
  border: 0 !important
}

.border-top-0 {
  border-top: 0 !important
}

.border-right-0 {
  border-right: 0 !important
}

.border-bottom-0 {
  border-bottom: 0 !important
}

.border-left-0 {
  border-left: 0 !important
}

.border-primary-color {
  border-color: var(--ion-color-primary) !important;
}

.border-secondary-color {
  border-color: var(--ion-color-secondary) !important
}

.border-success-color {
  border-color: var(--ion-color-success) !important
}

.border-info-color {
  border-color: #17a2b8 !important
}

.border-warning-color {
  border-color: var(--ion-color-warning) !important
}

.border-danger-color {
  border-color: var(--ion-color-danger) !important
}

.border-light-color {
  border-color: var(--ion-color-light) !important
}

.border-dark-color {
  border-color: var(--ion-color-dark) !important
}

.border-white-color {
  border-color: var(--ion-color-white) !important
}

.border-fusion-theme-color {
  border-color: var(--ion-color-fusion-theme) !important
}

.border-medium-color {
  border-color: var(--ion-color-medium) !important
}

.border-very-light-color {
  border-color: var(--ion-color-very-light) !important
}

.rounded-sm {
  border-radius: .2rem !important
}

.rounded {
  border-radius: .25rem !important
}

.rounded-top {
  border-top-left-radius: .25rem !important;
  border-top-right-radius: .25rem !important
}

.rounded-right {
  border-top-right-radius: .25rem !important;
  border-bottom-right-radius: .25rem !important
}

.rounded-bottom {
  border-bottom-right-radius: .25rem !important;
  border-bottom-left-radius: .25rem !important
}

.rounded-left {
  border-top-left-radius: .25rem !important;
  border-bottom-left-radius: .25rem !important
}

.rounded-lg {
  border-radius: .3rem !important
}

.rounded-circle {
  border-radius: 50% !important
}

.rounded-pill {
  border-radius: 50rem !important
}

.rounded-0 {
  border-radius: 0 !important
}

.clearfix::after {
  display: block;
  clear: both;
  content: ""
}

.align-baseline {
  vertical-align: baseline !important
}

.align-top {
  vertical-align: top !important
}

.align-middle {
  vertical-align: middle !important
}

.align-bottom {
  vertical-align: bottom !important
}

.align-text-bottom {
  vertical-align: text-bottom !important
}

.align-text-top {
  vertical-align: text-top !important
}

.pad {
  padding: 10px
}

.margin {
  margin: 10px
}

.margin-bottom {
  margin-bottom: 20px
}

.margin-bottom-none {
  margin-bottom: 0
}

.margin-r-5 {
  margin-right: 5px
}

.inline {
  display: inline
}

.description-block {
  display: block;
  margin: 10px 0;
  text-align: center
}

.description-block.margin-bottom {
  margin-bottom: 25px
}

.description-block>.description-header {
  margin: 0;
  padding: 0;
  font-weight: 600;
  font-size: 16px
}

.description-block>.description-text {
  text-transform: uppercase
}

.bg-red,
.bg-yellow,
.bg-aqua,
.bg-blue,
.bg-light-blue,
.bg-green,
.bg-navy,
.bg-teal,
.bg-olive,
.bg-lime,
.bg-orange,
.bg-fuchsia,
.bg-purple,
.bg-maroon,
.bg-black,
.bg-red-active,
.bg-yellow-active,
.bg-aqua-active,
.bg-blue-active,
.bg-light-blue-active,
.bg-green-active,
.bg-navy-active,
.bg-teal-active,
.bg-olive-active,
.bg-lime-active,
.bg-orange-active,
.bg-fuchsia-active,
.bg-purple-active,
.bg-maroon-active,
.bg-black-active,
.callout.callout-danger,
.callout.callout-warning,
.callout.callout-info,
.callout.callout-success,
.alert-success,
.alert-danger,
.alert-error,
.alert-warning,
.alert-info,
.label-danger,
.label-info,
.label-warning,
.label-primary,
.label-success,
.modal-primary .modal-body,
.modal-primary .modal-header,
.modal-primary .modal-footer,
.modal-warning .modal-body,
.modal-warning .modal-header,
.modal-warning .modal-footer,
.modal-info .modal-body,
.modal-info .modal-header,
.modal-info .modal-footer,
.modal-success .modal-body,
.modal-success .modal-header,
.modal-success .modal-footer,
.modal-danger .modal-body,
.modal-danger .modal-header,
.modal-danger .modal-footer {
  color: #fff !important
}

.bg-gray {
  color: #000;
  background-color: #d2d6de !important
}

.bg-gray-light {
  background-color: var(--ion-color-very-light) !important;
}

.bg-black {
  background-color: #1C1C1E !important
}

.bg-red,
.callout.callout-danger,
.alert-danger,
.alert-error,
.label-danger,
.modal-danger .modal-body {
  background-color: #dd4b39 !important
}

.bg-yellow,
.callout.callout-warning,
.alert-warning,
.label-warning,
.modal-warning .modal-body {
  background-color: #f39c12 !important
}

.bg-aqua,
.callout.callout-info,
.alert-info,
.label-info,
.modal-info .modal-body {
  background-color: #00c0ef !important
}

.bg-blue {
  background-color: #0073b7 !important
}

.bg-light-blue,
.label-primary,
.modal-primary .modal-body {
  background-color: #3c8dbc !important
}

.bg-green,
.callout.callout-success,
.alert-success,
.label-success,
.modal-success .modal-body {
  background-color: #00a65a !important
}

.bg-navy {
  background-color: #001f3f !important
}

.bg-teal {
  background-color: #39cccc !important
}

.bg-olive {
  background-color: #3d9970 !important
}

.bg-lime {
  background-color: #01ff70 !important
}

.bg-orange {
  background-color: #ff851b !important
}

.bg-fuchsia {
  background-color: #f012be !important
}

.bg-purple {
  background-color: var(--ion-color-primary) !important
}

.bg-maroon {
  background-color: #d81b60 !important
}

.bg-gray-active {
  color: #000;
  background-color: #b5bbc8 !important
}

.bg-black-active {
  background-color: #000 !important
}

.bg-red-active,
.modal-danger .modal-header,
.modal-danger .modal-footer {
  background-color: #d33724 !important
}

.bg-yellow-active,
.modal-warning .modal-header,
.modal-warning .modal-footer {
  background-color: #db8b0b !important
}

.bg-aqua-active,
.modal-info .modal-header,
.modal-info .modal-footer {
  background-color: #00a7d0 !important
}

.bg-blue-active {
  background-color: #005384 !important
}

.bg-light-blue-active,
.modal-primary .modal-header,
.modal-primary .modal-footer {
  background-color: #357ca5 !important
}

.bg-green-active,
.modal-success .modal-header,
.modal-success .modal-footer {
  background-color: #008d4c !important
}

.bg-navy-active {
  background-color: #001a35 !important
}

.bg-teal-active {
  background-color: #30bbbb !important
}

.bg-olive-active {
  background-color: #368763 !important
}

.bg-lime-active {
  background-color: #00e765 !important
}

.bg-orange-active {
  background-color: #ff7701 !important
}

.bg-fuchsia-active {
  background-color: #db0ead !important
}

.bg-purple-active {
  background-color: #555299 !important
}

.bg-maroon-active {
  background-color: #ca195a !important
}

[class^="bg-"].disabled {
  opacity: .65;
  filter: alpha(opacity=65)
}

.text-red {
  color: var(--ion-color-danger) !important
}

.text-yellow {
  color: #f39c12 !important
}

.text-aqua {
  color: #00c0ef !important
}

.text-blue {
  color: #0073b7 !important
}

.text-black {
  color: #111 !important
}

.text-light-blue {
  color: #3c8dbc !important
}

.text-green {
  color: #00a65a !important
}

.text-gray {
  color: #d2d6de !important
}

.text-navy {
  color: #001f3f !important
}

.text-teal {
  color: #39cccc !important
}

.text-olive {
  color: #3d9970 !important
}

.text-lime {
  color: #01ff70 !important
}

.text-orange {
  color: #ff851b !important
}

.text-fuchsia {
  color: #f012be !important
}

.vertical-align-middle {
  vertical-align: middle !important;
}

.text-purple {
  color: #7030A0 !important
}

.text-maroon {
  color: #d81b60 !important
}

.link-muted {
  color: #7a869d
}

.link-muted:hover,
.link-muted:focus {
  color: #606c84
}

.link-black {
  color: #666
}

.link-black:hover,
.link-black:focus {
  color: #999
}

.hide {
  display: none !important
}

.no-border {
  border: 0 !important
}

.no-padding {
  padding: 0 !important
}

.no-margin {
  margin: 0 !important
}

.no-shadow {
  box-shadow: none !important
}

.list-unstyled,
.chart-legend,
.contacts-list,
.users-list,
.mailbox-attachments {
  list-style: none;
  margin: 0;
  padding: 0
}

.list-group-unbordered>.list-group-item {
  border-left: 0;
  border-right: 0;
  border-radius: 0;
  padding-left: 0;
  padding-right: 0
}

.flat {
  border-radius: 0 !important
}

.text-bold,
.text-bold.table td,
.text-bold.table th {
  font-weight: 700
}

.text-sm {
  font-size: 12px !important
}

.jqstooltip {
  padding: 5px !important;
  width: auto !important;
  height: auto !important
}

.card {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #f5f8fd;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, .125);
  border-radius: 10px !important;
}

.card>hr {
  margin-right: 0;
  margin-left: 0
}

.card>.list-group:first-child .list-group-item:first-child {
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem
}

.card>.list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: .25rem;
  border-bottom-left-radius: .25rem
}

.card-body {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1.25rem
}

.card-title {
  margin-bottom: .75rem
}

.card-subtitle {
  margin-top: -.375rem;
  margin-bottom: 0
}

.card-text:last-child {
  margin-bottom: 0
}

.card-link:hover {
  text-decoration: none
}

.card-link+.card-link {
  margin-left: 1.25rem
}

.card-header {
  padding: .75rem 1.25rem;
  margin-bottom: 0;
  border-radius: 8px 8px 0 0 !important;
  background-color: var(--ion-color-alice-blue);
  // background-color: rgb(245, 245, 254, 0.5);
  // border-bottom: 1px solid rgba(0, 0, 0, .125);
  border-bottom: 1px solid rgba(245, 245, 254);

}

.card-header:first-child {
  border-radius: 0;
}

.card-header+.list-group .list-group-item:first-child {
  border-top: 0
}

.card-footer {
  padding: .75rem 1.25rem;
  border-radius: 0 0 8px 8px !important;
  background-color: var(--ion-color-alice-blue);
  // background-color: rgb(245, 245, 254, 0.5);
  // border-top: 1px solid rgba(0, 0, 0, .125)
  border-bottom: 1px solid rgba(245, 245, 254);
}

.card-footer:last-child {
  border-radius: 0;
}

.card-header-tabs {
  margin-right: -.625rem;
  margin-bottom: -.75rem;
  margin-left: -.625rem;
  border-bottom: 0
}

.card-header-pills {
  margin-right: -.625rem;
  margin-left: -.625rem
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem
}

.card-img {
  width: 100%;
  border-radius: calc(.25rem - 1px)
}

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(.25rem - 1px);
  border-top-right-radius: calc(.25rem - 1px)
}

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(.25rem - 1px);
  border-bottom-left-radius: calc(.25rem - 1px)
}

.card-deck {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column
}

.card-deck .card {
  margin-bottom: 15px
}

@media (min-width:576px) {
  .card-deck {
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px
  }

  .card-deck .card {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px
  }
}

.card-group {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column
}

.card-group>.card {
  margin-bottom: 15px
}

@media (min-width:576px) {
  .card-group {
    -ms-flex-flow: row wrap;
    flex-flow: row wrap
  }

  .card-group>.card {
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
    margin-bottom: 0
  }

  .card-group>.card+.card {
    margin-left: 0;
    border-left: 0
  }

  .card-group>.card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
  }

  .card-group>.card:not(:last-child) .card-header,
  .card-group>.card:not(:last-child) .card-img-top {
    border-top-right-radius: 0
  }

  .card-group>.card:not(:last-child) .card-footer,
  .card-group>.card:not(:last-child) .card-img-bottom {
    border-bottom-right-radius: 0
  }

  .card-group>.card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
  }

  .card-group>.card:not(:first-child) .card-header,
  .card-group>.card:not(:first-child) .card-img-top {
    border-top-left-radius: 0
  }

  .card-group>.card:not(:first-child) .card-footer,
  .card-group>.card:not(:first-child) .card-img-bottom {
    border-bottom-left-radius: 0
  }
}

.card-columns .card {
  margin-bottom: .75rem
}

@media (min-width:576px) {
  .card-columns {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    -webkit-column-gap: 1.25rem;
    -moz-column-gap: 1.25rem;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1
  }

  .card-columns .card {
    display: inline-block;
    width: 100%
  }
}

.scrolling-wrapper {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}

.scrolling-wrapper .card {
  display: inline-block;
  border-radius: 12px;
  min-width: 25%;
}

.scrolling-wrapper .card-inventory-dashboard {
  display: inline-block;
  border-radius: 8px;
  min-width: 200px;
}

.text-white {
  color: #fff !important;
}

::-webkit-scrollbar {
  display: none;
}

.service::-webkit-scrollbar {
  height: 4px !important;
  display: block;
}

.service::-webkit-scrollbar-track:horizontal {
  background-color: var(--ion-color-fusion-theme) !important;
  border-radius: 32px !important;
}

.service::-webkit-scrollbar-thumb:horizontal {
  border-radius: 32px !important;
  background-color: var(--ion-color-primary) !important;
}

.service::-webkit-scrollbar-track {
  border-radius: 32px !important;
}

.offers-list::-webkit-scrollbar {
  height: 4px !important;
  display: block;
}

.offers-list::-webkit-scrollbar-track:horizontal {
  background-color: var(--ion-color-fusion-theme) !important;
  border-radius: 32px !important;
}

.offers-list::-webkit-scrollbar-thumb:horizontal {
  border-radius: 32px !important;
  background-color: var(--ion-color-primary) !important;
}

.offers-list::-webkit-scrollbar-track {
  border-radius: 32px !important;

}


@media only screen and (min-width: 320px) and (max-width: 736px) {
  .service::-webkit-scrollbar-track {
    margin: 0 125px !important;
  }

  .offers-list::-webkit-scrollbar-track {
    margin: 0 125px !important;
  }
}

@media only screen and (min-width: 737px) and (max-width: 991px) {
  .service::-webkit-scrollbar-track {
    margin: 0 300px !important;
  }

  .offers-list::-webkit-scrollbar-track {
    margin: 0 300px !important;
  }
}

@media only screen and (min-width: 992px) and (max-width: 2048px) {
  .service::-webkit-scrollbar-track {
    margin: 0 500px !important;
  }

  .offers-list::-webkit-scrollbar-track {
    margin: 0 500px !important;
  }
}

.widget-user .icon {
  -webkit-transition: all .3s linear;
  -o-transition: all .3s linear;
  transition: all .3s linear;
  position: absolute;
  top: -10px;
  right: 10px;
  z-index: 0;
  font-size: 90px;
  color: rgba(0, 0, 0, 0.15)
}

.widget-user:hover {
  text-decoration: none;
  color: #f9f9f9
}

.widget-user:hover .icon {
  font-size: 95px
}

.round-border {
  border-radius: 100px;
  border: 1px solid #fff;
  padding: 2px 15px 2px 15px;
  color: #fff;
  font-size: 16px;
}

/* Top left text */
.top-left {
  position: absolute;
  top: 8px;
  left: 16px;
}

/* Top right text */
.top-right {
  position: absolute;
  top: 8px;
  right: 16px;
}

/* Bottom right text */
.bottom-right {
  position: absolute;
  bottom: 8px;
  right: 16px;
}

/* Centered text */
.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.card-shadow {
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.35) !important;
  transition: -webkit-filter .3s ease !important;
  transition: filter .3s ease !important;
  transition: filter .3s ease, -webkit-filter .3s ease !important;
  opacity: 1 !important;
}

.shadow {

  box-shadow: 0 5px 10px rgba(154, 160, 185, .05), 0 15px 40px rgba(166, 173, 201, .2);

}

.b-r-0 {
  border-radius: 0px !important;
}

.b-r-2 {
  border-radius: 2px !important;
}

.b-r-4 {
  border-radius: 4px !important;
}

.b-r-6 {
  border-radius: 6px !important;
}

.b-r-8 {
  border-radius: 8px !important;
}

.b-r-10 {
  border-radius: 10px !important;
}

.b-r-12 {
  border-radius: 12px !important;
}

.b-r-16 {
  border-radius: 16px !important;
}

.b-r-20 {
  border-radius: 20px !important;
}

.b-r-32 {
  border-radius: 32px !important;
}

.b-r-100 {
  border-radius: 100px !important;
}

.btn-round {

  width: 60px !important;
  height: 60px !important;
  border-radius: 100% !important;
}

.btn-round-small {

  width: 40px !important;
  height: 40px !important;
  border-radius: 100% !important;
}

.quicklinks-wrapper {
  width: 100%;
  height: 45px;
  overflow: hidden;
}

.quicklinks {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: stretch;
  overflow-x: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  height: 55px;
}

.quicklinks-item {
  text-align: center;
  padding: 0px 3px;
  font-weight: normal;
  flex-grow: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 2px solid transparent;
  height: 45px;

  a {
    text-align: center;
  }


}

.quicklinks-item:first-child {
  padding-left: 6px;
}

.home-menu {
  width: 22px;
  height: 22px;
}

.home-menu-bar {
  width: 26px;
  height: 26px;
}

.home-user-icon {
  width: 32px;
  height: 32px;
  border-radius: 100px;
}



.intro_slides {
  width: 80% !important;
}

.axis_slides {
  width: 100% !important;
  border-radius: 6px;
}

ion-slide {
  display: block !important;
  margin-bottom: 30px !important;
}

ion-slides {
  --bullet-background: transparent !important;
  --bullet-background-active: var(--ion-color-warning) !important;

  .swiper-pagination-bullet {
    width: 6px;
    height: 6px;
    border-radius: 100px;
    border: 2px solid var(--ion-color-white) !important;
  }

  .swiper-pagination-bullet-active {
    width: 26px;
    height: 6px;
    border-radius: 6px;
    border: 0px solid var(--ion-color-primary) !important;

  }
}




@font-face {

  font-family: "hylobiz-global-font-medium";
  src: url("../assets/fonts/Inter/Inter-VariableFont_slnt,wght.ttf") format('truetype');
  font-weight: 500;
  font-style: normal;

}

@font-face {

  font-family: "hylobiz-global-font-regular";
  src: url("../assets/fonts/Inter/Inter-VariableFont_slnt,wght.ttf") format('truetype');
  font-weight: 300;
  font-style: normal;

}

@font-face {

  font-family: "hylobiz-global-font-light";
  src: url("../assets/fonts/Inter/Inter-VariableFont_slnt,wght.ttf") format('truetype');
  font-weight: 300;
  font-style: normal;

}

@font-face {

  font-family: "hylobiz-global-font-extra-light";
  src: url("../assets/fonts/Inter/Inter-VariableFont_slnt,wght.ttf") format('truetype');
  font-weight: 200;
  font-style: normal;

}

@font-face {

  font-family: "hylobiz-global-font-bold";
  src: url("../assets/fonts/Inter/Inter-VariableFont_slnt,wght.ttf") format('truetype');
  font-weight: 500;
  font-style: normal;

}

@font-face {

  font-family: "hylobiz-global-font-extra-bold";
  src: url("../assets/fonts/Inter/Inter-VariableFont_slnt,wght.ttf") format('truetype');
  font-weight: 600;
  font-style: normal;

}

@font-face {

  font-family: "hylobiz-global-font-super-bold";
  src: url("../assets/fonts/Inter/Inter-VariableFont_slnt,wght.ttf") format('truetype');
  font-weight: 800;
  font-style: normal;

}

@font-face {

  font-family: "hylobiz-global-font-semi-bold";
  src: url("../assets/fonts/Inter/Inter-VariableFont_slnt,wght.ttf") format('truetype');
  font-weight: 400;
  font-style: normal;

}


.fw-normal {
  font-family: "hylobiz-global-font-regular", sans-serif !important;
}

.fw-bold {
  font-family: "hylobiz-global-font-bold", sans-serif !important;
}

.fw-extra-bold {
  font-family: "hylobiz-global-font-extra-bold", sans-serif !important;
}

.fw-semi-bold {
  font-family: "hylobiz-global-font-semi-bold", sans-serif !important;
}

.fw-light {
  font-family: "hylobiz-global-font-light", sans-serif !important;
}

.fw-extra-light {
  font-family: "hylobiz-global-font-extra-light", sans-serif !important;
}

.fw-regular {
  font-family: "hylobiz-global-font-regular", sans-serif !important;
}

.fw-super-bold {
  font-family: "hylobiz-global-font-super-bold", sans-serif !important;
}

.logo-light {
  font-family: 'hylo-font-light', sans-serif !important;
}

.strong-font {
  font-family: 'hylo-font-thick', sans-serif !important;
}

.medium-font {
  font-family: 'hylo-font-medium', sans-serif !important;
}

.brand-logo-large {
  width: 160px;
  vertical-align: middle !important;
}

.brand-logo {
  width: 100px;
  vertical-align: middle !important;
}

.brand-logo-small {
  width: 95px;
  vertical-align: middle !important;
}

.pull-left {
  float: left;
}

.pull-right {
  float: right;
}

.logo {
  width: 80px;
}

ion-item {


  --background: transparent !important;
  --ion-item-background: transparent;
  --ion-item-background-activated: transparent !important;
  --background-focused-opacity: 0 !important;
  --padding-end: 0px;
  --inner-padding-top: 0rem !important;
  --inner-padding-bottom: 0rem !important;
  --inner-padding-start: 0.25rem !important;
  --inner-padding-end: 0.25rem !important;
  --detail-icon-color: var(--ion-color-medium) !important;
  --detail-icon-opacity: 1 !important;
}

ion-item.item-has-focus>ion-label {
  color: var(--ion-color-dark) !important;
  font-weight: 500 !important;
  font-size: 16px !important;
}

.customer-logo {
  width: 40px;
  height: 40px;
  border-radius: 100px;
}

.text-ellipsis {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.card-invoice {
  width: 250px !important;
}

.order-outer {
  width: 48px;
  height: 56px;
  text-align: center;
  margin: auto;
  border-radius: 0px !important;
  align-items: center;
  position: relative;
}

.order-date {
  padding: 2px;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis !important;
  font-size: 12px;
  text-transform: capitalize;
}

.chat {
  padding: 5px 20px 5px 10px
}

.chat .item {
  margin-bottom: 10px
}

.chat .item:before,
.chat .item:after {
  content: " ";
  display: table
}

.chat .item:after {
  clear: both
}

.chat .item>img {
  width: 40px;
  height: 40px;
  border: 2px solid transparent;
  border-radius: 50%
}

.chat .item>.online {
  border: 2px solid #00a65a
}

.chat .item>.offline {
  border: 2px solid #dd4b39
}

.chat .item>.message {
  margin-left: 55px;
  margin-top: -40px
}

.chat .item>.message>.name {
  display: block;
  font-weight: 600
}

.chat .item>.attachment {
  border-radius: 3px;
  background: #f4f4f4;
  margin-left: 65px;
  margin-right: 15px;
  padding: 10px
}

.chat .item>.attachment>h4 {
  margin: 0 0 5px 0;
  font-weight: 600;
  font-size: 14px
}

.chat .item>.attachment>p,
.chat .item>.attachment>.filename {
  font-weight: 600;
  font-size: 13px;
  font-style: italic;
  margin: 0
}

.chat .item>.attachment:before,
.chat .item>.attachment:after {
  content: " ";
  display: table
}

.chat .item>.attachment:after {
  clear: both
}

.products-list {
  list-style: none;
  margin: 0;
  padding: 0
}

.products-list>.item {
  border-radius: 3px;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  padding: 10px 0;
  background: #fff
}

.products-list>.item:before,
.products-list>.item:after {
  content: " ";
  display: table
}

.products-list>.item:after {
  clear: both
}

.products-list .product-img {
  float: left
}

.products-list .product-img img {
  width: 50px;
  height: 50px
}

.products-list .product-info {
  margin-left: 60px
}

.products-list .product-title {
  font-weight: 400
}

.products-list .product-description {
  display: block;
  color: #999;
  overflow: hidden;
}

.product-list-in-box>.item {
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 0;
  border-bottom: 1px solid #f4f4f4
}

.product-list-in-box>.item:last-of-type {
  border-bottom-width: 0
}

.text-muted {
  color: #999 !important;
}

.border-left-initiated {
  border-left: 3px solid #92949c !important;
}

.border-left-progress {
  border-left: 3px solid #F6C645 !important;
}

.border-left-pending {
  border-left: 3px solid #4338ca !important;
}

.border-left-processed {
  border-left: 3px solid #15803d !important;

}

.border-left-cancel {
  border-left: 3px solid #c34955 !important;
}



.bg-table-even-background {
  background: #f9f9fe;
}

.border-light-fusion-theme-border {
  border: 2px solid #f3f4fa !important;
}

.border-medium {
  border: 1px solid var(--ion-color-medium) !important;
}


a {
  color: var(--ion-color-primary);
  text-decoration: none;
}

a:hover,
a:active,
a:focus {

  outline: none;
  text-decoration: none;

}

.sidebar-menu-icons {
  ion-thumbnail {
    width: 30px !important;
    height: 30px !important;
  }

  img {
    width: 16px !important;
    height: 16px !important;
    position: relative;
    display: block;
    margin-top: 7px;
    margin-left: auto;
    margin-right: auto;
  }
}

.close-icon {
  width: 28px;
  height: 28px;
  border-radius: 100px;
  border: 3px solid #f4f5f8;
}

.country-flag {
  width: 20px;
  height: 20px;
  vertical-align: middle;
}

.custom-icons {
  width: 32px;
  height: 32px;
  vertical-align: middle;
}

.customer-business-card-logo {
  width: 48px;
  height: 48px;
  border-radius: 100px;
}

.customer-business-card-logo-small {
  width: 24px;
  height: 24px;
  border-radius: 100px;
}

.qr-code {
  width: 50px;
  height: 50px;
}

.psp-images {
  width: 24px;
}

.psp-images-amazon {
  width: 40px;
}

.custom-header {
  h1::after {
    content: '';
    display: block;
    margin-top: 10px;
    width: 50px;
    height: 5px;
    background: #7030A0;
    border-radius: 100px;
  }
}

ion-input {
  caret-color: var(--ion-color-dark) !important;
  --placeholder-color: var(--ion-color-medium) !important;
  font-size: 16px !important;
}

ion-datetime {
  caret-color: var(--ion-color-dark) !important;
  font-size: 16px !important;
}

ion-select {
  caret-color: var(--ion-color-dark) !important;
  font-size: 16px !important;
}

ion-textarea {
  caret-color: var(--ion-color-dark) !important;
  font-size: 16px !important;
}

ion-searchbar {
  --border-radius: 6px !important;
  caret-color: var(--ion-color-dark) !important;
  --placeholder-opacity: 0.35 !important;
  --box-shadow: none !important;
}

//Custom-Toast

.custom-toast-message-browser {
  --border-radius: 6px !important;
  --padding: 8px 16px !important;
  --width: 90% !important;
  margin-right: 0px !important;
  --min-height: 70px !important;
  left: 150px !important;
}

.custom-toast-message {
  --border-radius: 6px !important;
  --padding: 8px 16px !important;
  --width: 90% !important;
  margin-right: 0px !important;
  --min-height: 70px !important;
  font-size: 16px !important;
}

ion-loading.custom-loading {
  --background: none !important;
  --spinner-color: #fff !important;
  --background: #000 !important;
  --backdrop-opacity: 0.40 !important;

  .loading-wrapper {
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
    --background: transparent !important;
    --background: none !important;
  }

}

ion-spinner {
  transform: scale(0.8);
}

ion-radio {
  --color: var(--ion-color-primary) !important;
  width: 16px !important;
  height: 16px !important;
  background: var(--ion-color-very-light) !important;
  border-radius: 50%;
}


.close-icon-light {
  width: 28px;
  height: 28px;
  border-radius: 100px;
  border: 3px solid #f6f6f6;
}

:focus {
  outline: none !important;
}

.item-ios {
  background: transparent !important;
}

.custom-hr {
  height: 1px;
  background: var(--ion-color-white);
  border-radius: 100px;
}

.dotted-hr {
  height: 1px;
  background: var(--ion-color-white);
  border-radius: 100px;
}

.custom-hr-inverse {
  height: 3px;
  background: var(--ion-color-white);
  border-radius: 100px;
}

.custom-modal-dismiss-bar {
  height: 4px;
  width: 15%;
  background: var(--ion-color-medium);
  border-radius: 100px;
}

.text-left .action-sheet-button-inner.sc-ion-action-sheet-ios {
  justify-content: flex-start;
}

.timeline {
  position: relative;
  margin: 0 0 0px 0;
  padding: 0;
  color: transparent;
}

.timeline:before {
  content: '';
  position: absolute;
  top: 0px;
  bottom: -20px;
  width: 2px;
  //background: var(--ion-color-primary-shade);
  background: var(--ion-color-medium);
  left: 15px;
  margin: 0;
  border-radius: 16px;
}

.timeline>li {
  position: relative;
  margin-right: 0px;
  margin-bottom: 15px
}

.timeline>li:before,
.timeline>li:after {
  content: " ";
  display: table
}

.timeline>li:after {
  clear: both
}

.timeline>li>.timeline-item {
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  margin-top: -20px;
  background: var(--ion-color-white);
  color: #444;
  margin-left: 40px;
  margin-right: 0px;
  padding: 0;
  position: relative;
  border: 2px solid var(--ion-color-light);
}

.timeline>li>.timeline-item>.time {
  color: #999;
  float: right;
  padding: 10px;
  font-size: 12px
}

.timeline>li>.timeline-item>.timeline-header {
  margin: 0;
  color: #555;
  //border-bottom: 2px solid var(--ion-color-fusion-theme);
  padding: 10px;
  font-size: 16px;
  line-height: 1.1;
  background-color: rgb(245, 245, 254, 0.5);
  border-radius: 4px 4px 0 0;
}

.timeline>li>.timeline-item>.timeline-header>a {
  font-weight: 600
}

.timeline>li>.timeline-item>.timeline-body {
  padding: 10px;
}

.timeline>li>.timeline-item>.timeline-footer {
  padding: 10px;
  //border-top: 2px solid var(--ion-color-fusion-theme);
  background-color: rgb(245, 245, 254, 0.5);
  border-radius: 0 0 4px 4px;
}

.timeline>li>ion-icon {
  width: 20px;
  height: 20px;
  font-size: 15px;
  line-height: 20px;
  position: absolute;
  color: #fff;
  background: var(--ion-color-success);
  border-radius: 50%;
  text-align: center;
  left: 4px;
  padding: 2px;
  top: 0
}

.timeline>.time-label>span {
  font-weight: 500;
  padding: 8px;
  display: inline-block;
  background-color: var(--ion-color-very-light-shade);
  border-radius: 4px;
  margin-top: -30px;
  //border: 2px solid var(--ion-color-primary-shade);
  border: 2px solid var(--ion-color-medium);
  color: var(--ion-color-dark);
}

.timeline-inverse>li>.timeline-item {
  background: #f0f0f0;
  border: 1px solid #ddd;
  -webkit-box-shadow: none;
  box-shadow: none
}

.timeline-inverse>li>.timeline-item>.timeline-header {
  border-bottom-color: #ddd
}

.contact-logo {
  width: 48px;
  border-radius: 100%;
  vertical-align: middle;
}

.multiline-text-ellipsis {
  display: block !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 2 !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  -webkit-box-orient: vertical !important;
}

.settings-user-icon {
  width: 64px;
  height: 64px;
  border-radius: 100px;
}


.edit-information {
  background: rgb(0, 122, 255);
  border-radius: 8px;
}

.edit-user-information {
  background: rgb(52, 199, 89);
  border-radius: 8px;
}

.team {
  background: rgb(88, 86, 214);
  border-radius: 8px;
}

.change-password {
  background: rgb(255, 149, 0);
  border-radius: 8px;
}

.home-menu {
  background: rgb(0, 122, 255);
  border-radius: 8px;
}

.receivables-menu {
  background: rgb(52, 199, 89);
  border-radius: 8px;
}

.payables-menu {
  background: rgb(88, 86, 214);
  border-radius: 8px;
}

.ledger-menu {
  background: rgb(255, 149, 0);
  border-radius: 8px;
}

.settings-menu {
  background: rgb(255, 45, 85);
  border-radius: 8px;
}

.stock-house-menu {
  background: rgb(0, 122, 255);
  border-radius: 8px;
}

.purchase-order-menu {
  background: rgb(88, 86, 214);
  border-radius: 8px;
}

.marketplace-menu {
  background: rgb(49, 46, 129);
  border-radius: 8px;
}

.reports-menu {
  background: rgb(255, 59, 48);
  border-radius: 8px;
}

.dark-mode {
  background: rgb(255, 59, 48);
  border-radius: 8px;
}

.security-shield {
  background: rgb(52, 199, 89);
  border-radius: 8px;
}

.only-white {
  background: #fff;
}

.icon-success {
  font-size: 100px;
}

.bg-only-white {
  background: #fff;
}

.vertical-align-baseline {
  vertical-align: baseline;
}

.border-primary {
  border: 1px solid var(--ion-color-primary);
}

.border-secondary {
  border: 1px solid var(--ion-color-secondary);
}

.border-success {
  border: 1px solid var(--ion-color-success);
}

.border-info {
  border: 1px solid #17a2b8;
}

.border-warning {
  border: 1px solid var(--ion-color-warning);
}

.border-danger {
  border: 1px solid var(--ion-color-danger);
}

.border-light {
  border: 1px solid var(--ion-color-light);
}

.border-dark {
  border: 1px solid var(--ion-color-dark);
}

.border-white {
  border: 1px solid var(--ion-color-white);
}

.border-pending {
  border: 2px solid var(--ion-color-pending);
}

.border-paid {
  border: 2px solid var(--ion-color-paid);
}

.border-failed {
  border: 2px solid var(--ion-color-failed);
}

.border-partial {
  border: 2px solid var(--ion-color-partial);
}

.border-tertiary {
  border: 2px solid var(--ion-color-light);
}

.border-minty-green-800 {
  border: 2px solid var(--ion-color-minty-green-800);
}

.text-icons-primary ion-icon {
  color: var(--ion-color-primary);
  padding-right: 10px;
}

.custom-css-alert-box {

  --backdrop-opacity: 0.8;
  --max-width: 80%;
  font-family: "hylobiz-global-font-bold", sans-serif !important;

  h1 {
    color: var(--ion-color-warning);
    font-size: 64px;
  }

  h5 {
    color: var(--ion-color-medium);
  }

  h6 {
    font-weight: normal;
    color: var(--ion-color-medium);
  }
}

.alert-message {
  font-size: 16px !important;
}

.neumorph-card {
  background: var(--ion-color-light);
}

.receivables-arrow {
  position: relative;
  float: right;
  margin-top: -25px;
  margin-right: -25px;
  color: #fff;
  font-size: 96px;
  font-weight: bold;
}

.payables-arrow {
  position: relative;
  float: right;
  margin-top: -20px;
  margin-right: -25px;
  color: #fff;
  font-size: 96px;
  font-weight: bold;
}


.card-dots {
  color: #fff;
  font-size: 72px;
  font-weight: bold;
  position: relative;
  float: right;
  margin-top: -25px;
  margin-right: -10px;
}

.custom-popover {
  --backdrop-opacity: 0.85;
}

.gradient-card-one {
  color: #fff;
  background: linear-gradient(-45deg, #03256C, #2541B2, #150E56, #23049D);
  background-size: 400% 400%;
  -webkit-animation: gradient 30s ease infinite;
  animation: gradient 30s ease infinite;
}

.gradient-card-two {
  color: #fff;
  background: linear-gradient(-45deg, #413C69, #4A47A3, #111D5E, #161D6F);
  background-size: 400% 400%;
  -webkit-animation: gradient 30s ease infinite;
  animation: gradient 30s ease infinite;
}

@-webkit-keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.dashboard-card {
  width: 360px;
  min-height: 270px;
}

.custom-css-alert-box-fast-signin {
  --backdrop-opacity: 0.7;
  --max-width: 80%;

  h1 {
    color: var(--ion-color-primary);
    font-size: 64px;
  }

  h5 {
    color: var(--ion-color-medium);
  }

  h6 {
    font-weight: normal;
    color: var(--ion-color-medium);
  }
}

.contact-info-card {
  border: 2px solid var(--ion-color-pending);
  border-radius: 10px;
  padding: 16px;
}

.ewayBill-list-card {
  border: 1px solid var(--ion-color-medium);
  border-radius: 6px;
  padding: 16px;
}



/************************** ANIMATION CSS STARTS **************************/

@-webkit-keyframes bounce {

  from,
  20%,
  53%,
  80%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  40%,
  43% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -30px, 0);
    transform: translate3d(0, -30px, 0);
  }

  70% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -15px, 0);
    transform: translate3d(0, -15px, 0);
  }

  90% {
    -webkit-transform: translate3d(0, -4px, 0);
    transform: translate3d(0, -4px, 0);
  }
}

@keyframes bounce {

  from,
  20%,
  53%,
  80%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  40%,
  43% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -30px, 0);
    transform: translate3d(0, -30px, 0);
  }

  70% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -15px, 0);
    transform: translate3d(0, -15px, 0);
  }

  90% {
    -webkit-transform: translate3d(0, -4px, 0);
    transform: translate3d(0, -4px, 0);
  }
}

.bounce {
  -webkit-animation-name: bounce;
  animation-name: bounce;
  -webkit-transform-origin: center bottom;
  transform-origin: center bottom;
}

@-webkit-keyframes flash {

  from,
  50%,
  to {
    opacity: 1;
  }

  25%,
  75% {
    opacity: 0;
  }
}

@keyframes flash {

  from,
  50%,
  to {
    opacity: 1;
  }

  25%,
  75% {
    opacity: 0;
  }
}

.flash {
  -webkit-animation-name: flash;
  animation-name: flash;
}

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */

@-webkit-keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05);
  }

  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05);
  }

  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

.pulse {
  -webkit-animation-name: pulse;
  animation-name: pulse;
}

@-webkit-keyframes rubberBand {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1);
  }

  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1);
  }

  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1);
  }

  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1);
  }

  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1);
  }

  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@keyframes rubberBand {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1);
  }

  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1);
  }

  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1);
  }

  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1);
  }

  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1);
  }

  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

.rubberBand {
  -webkit-animation-name: rubberBand;
  animation-name: rubberBand;
}

@-webkit-keyframes shake {

  from,
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  10%,
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
  }

  20%,
  40%,
  60%,
  80% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
  }
}

@keyframes shake {

  from,
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  10%,
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
  }

  20%,
  40%,
  60%,
  80% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
  }
}

.shake {
  -webkit-animation-name: shake;
  animation-name: shake;
}

@-webkit-keyframes headShake {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  6.5% {
    -webkit-transform: translateX(-6px) rotateY(-9deg);
    transform: translateX(-6px) rotateY(-9deg);
  }

  18.5% {
    -webkit-transform: translateX(5px) rotateY(7deg);
    transform: translateX(5px) rotateY(7deg);
  }

  31.5% {
    -webkit-transform: translateX(-3px) rotateY(-5deg);
    transform: translateX(-3px) rotateY(-5deg);
  }

  43.5% {
    -webkit-transform: translateX(2px) rotateY(3deg);
    transform: translateX(2px) rotateY(3deg);
  }

  50% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes headShake {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  6.5% {
    -webkit-transform: translateX(-6px) rotateY(-9deg);
    transform: translateX(-6px) rotateY(-9deg);
  }

  18.5% {
    -webkit-transform: translateX(5px) rotateY(7deg);
    transform: translateX(5px) rotateY(7deg);
  }

  31.5% {
    -webkit-transform: translateX(-3px) rotateY(-5deg);
    transform: translateX(-3px) rotateY(-5deg);
  }

  43.5% {
    -webkit-transform: translateX(2px) rotateY(3deg);
    transform: translateX(2px) rotateY(3deg);
  }

  50% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

.headShake {
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-name: headShake;
  animation-name: headShake;
}

@-webkit-keyframes swing {
  20% {
    -webkit-transform: rotate3d(0, 0, 1, 15deg);
    transform: rotate3d(0, 0, 1, 15deg);
  }

  40% {
    -webkit-transform: rotate3d(0, 0, 1, -10deg);
    transform: rotate3d(0, 0, 1, -10deg);
  }

  60% {
    -webkit-transform: rotate3d(0, 0, 1, 5deg);
    transform: rotate3d(0, 0, 1, 5deg);
  }

  80% {
    -webkit-transform: rotate3d(0, 0, 1, -5deg);
    transform: rotate3d(0, 0, 1, -5deg);
  }

  to {
    -webkit-transform: rotate3d(0, 0, 1, 0deg);
    transform: rotate3d(0, 0, 1, 0deg);
  }
}

@keyframes swing {
  20% {
    -webkit-transform: rotate3d(0, 0, 1, 15deg);
    transform: rotate3d(0, 0, 1, 15deg);
  }

  40% {
    -webkit-transform: rotate3d(0, 0, 1, -10deg);
    transform: rotate3d(0, 0, 1, -10deg);
  }

  60% {
    -webkit-transform: rotate3d(0, 0, 1, 5deg);
    transform: rotate3d(0, 0, 1, 5deg);
  }

  80% {
    -webkit-transform: rotate3d(0, 0, 1, -5deg);
    transform: rotate3d(0, 0, 1, -5deg);
  }

  to {
    -webkit-transform: rotate3d(0, 0, 1, 0deg);
    transform: rotate3d(0, 0, 1, 0deg);
  }
}

.swing {
  -webkit-transform-origin: top center;
  transform-origin: top center;
  -webkit-animation-name: swing;
  animation-name: swing;
}

@-webkit-keyframes tada {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  10%,
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
  }

  30%,
  50%,
  70%,
  90% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  }

  40%,
  60%,
  80% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  }

  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@keyframes tada {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  10%,
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
  }

  30%,
  50%,
  70%,
  90% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  }

  40%,
  60%,
  80% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  }

  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

.tada {
  -webkit-animation-name: tada;
  animation-name: tada;
}

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */

@-webkit-keyframes wobble {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  15% {
    -webkit-transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
    transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
  }

  30% {
    -webkit-transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
    transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
  }

  45% {
    -webkit-transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
    transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
  }

  60% {
    -webkit-transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
    transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
  }

  75% {
    -webkit-transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
    transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes wobble {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  15% {
    -webkit-transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
    transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
  }

  30% {
    -webkit-transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
    transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
  }

  45% {
    -webkit-transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
    transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
  }

  60% {
    -webkit-transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
    transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
  }

  75% {
    -webkit-transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
    transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.wobble {
  -webkit-animation-name: wobble;
  animation-name: wobble;
}

@-webkit-keyframes jello {

  from,
  11.1%,
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  22.2% {
    -webkit-transform: skewX(-12.5deg) skewY(-12.5deg);
    transform: skewX(-12.5deg) skewY(-12.5deg);
  }

  33.3% {
    -webkit-transform: skewX(6.25deg) skewY(6.25deg);
    transform: skewX(6.25deg) skewY(6.25deg);
  }

  44.4% {
    -webkit-transform: skewX(-3.125deg) skewY(-3.125deg);
    transform: skewX(-3.125deg) skewY(-3.125deg);
  }

  55.5% {
    -webkit-transform: skewX(1.5625deg) skewY(1.5625deg);
    transform: skewX(1.5625deg) skewY(1.5625deg);
  }

  66.6% {
    -webkit-transform: skewX(-0.78125deg) skewY(-0.78125deg);
    transform: skewX(-0.78125deg) skewY(-0.78125deg);
  }

  77.7% {
    -webkit-transform: skewX(0.390625deg) skewY(0.390625deg);
    transform: skewX(0.390625deg) skewY(0.390625deg);
  }

  88.8% {
    -webkit-transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
    transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
  }
}

@keyframes jello {

  from,
  11.1%,
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  22.2% {
    -webkit-transform: skewX(-12.5deg) skewY(-12.5deg);
    transform: skewX(-12.5deg) skewY(-12.5deg);
  }

  33.3% {
    -webkit-transform: skewX(6.25deg) skewY(6.25deg);
    transform: skewX(6.25deg) skewY(6.25deg);
  }

  44.4% {
    -webkit-transform: skewX(-3.125deg) skewY(-3.125deg);
    transform: skewX(-3.125deg) skewY(-3.125deg);
  }

  55.5% {
    -webkit-transform: skewX(1.5625deg) skewY(1.5625deg);
    transform: skewX(1.5625deg) skewY(1.5625deg);
  }

  66.6% {
    -webkit-transform: skewX(-0.78125deg) skewY(-0.78125deg);
    transform: skewX(-0.78125deg) skewY(-0.78125deg);
  }

  77.7% {
    -webkit-transform: skewX(0.390625deg) skewY(0.390625deg);
    transform: skewX(0.390625deg) skewY(0.390625deg);
  }

  88.8% {
    -webkit-transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
    transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
  }
}

.jello {
  -webkit-animation-name: jello;
  animation-name: jello;
  -webkit-transform-origin: center;
  transform-origin: center;
}

@-webkit-keyframes heartBeat {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  14% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
  }

  28% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  42% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
  }

  70% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes heartBeat {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  14% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
  }

  28% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  42% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
  }

  70% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.heartBeat {
  -webkit-animation-name: heartBeat;
  animation-name: heartBeat;
  -webkit-animation-duration: 1.3s;
  animation-duration: 1.3s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
}

@-webkit-keyframes bounceIn {

  from,
  20%,
  40%,
  60%,
  80%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }

  20% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }

  40% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
    transform: scale3d(1.03, 1.03, 1.03);
  }

  80% {
    -webkit-transform: scale3d(0.97, 0.97, 0.97);
    transform: scale3d(0.97, 0.97, 0.97);
  }

  to {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@keyframes bounceIn {

  from,
  20%,
  40%,
  60%,
  80%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }

  20% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }

  40% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
    transform: scale3d(1.03, 1.03, 1.03);
  }

  80% {
    -webkit-transform: scale3d(0.97, 0.97, 0.97);
    transform: scale3d(0.97, 0.97, 0.97);
  }

  to {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

.bounceIn {
  -webkit-animation-duration: 0.75s;
  animation-duration: 0.75s;
  -webkit-animation-name: bounceIn;
  animation-name: bounceIn;
}

@-webkit-keyframes bounceInDown {

  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -3000px, 0);
    transform: translate3d(0, -3000px, 0);
  }

  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, 25px, 0);
    transform: translate3d(0, 25px, 0);
  }

  75% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
  }

  90% {
    -webkit-transform: translate3d(0, 5px, 0);
    transform: translate3d(0, 5px, 0);
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes bounceInDown {

  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -3000px, 0);
    transform: translate3d(0, -3000px, 0);
  }

  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, 25px, 0);
    transform: translate3d(0, 25px, 0);
  }

  75% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
  }

  90% {
    -webkit-transform: translate3d(0, 5px, 0);
    transform: translate3d(0, 5px, 0);
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.bounceInDown {
  -webkit-animation-name: bounceInDown;
  animation-name: bounceInDown;
}

@-webkit-keyframes bounceInLeft {

  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
    transform: translate3d(-3000px, 0, 0);
  }

  60% {
    opacity: 1;
    -webkit-transform: translate3d(25px, 0, 0);
    transform: translate3d(25px, 0, 0);
  }

  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
  }

  90% {
    -webkit-transform: translate3d(5px, 0, 0);
    transform: translate3d(5px, 0, 0);
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes bounceInLeft {

  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
    transform: translate3d(-3000px, 0, 0);
  }

  60% {
    opacity: 1;
    -webkit-transform: translate3d(25px, 0, 0);
    transform: translate3d(25px, 0, 0);
  }

  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
  }

  90% {
    -webkit-transform: translate3d(5px, 0, 0);
    transform: translate3d(5px, 0, 0);
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.bounceInLeft {
  -webkit-animation-name: bounceInLeft;
  animation-name: bounceInLeft;
}

@-webkit-keyframes bounceInRight {

  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  from {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
    transform: translate3d(3000px, 0, 0);
  }

  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
    transform: translate3d(-25px, 0, 0);
  }

  75% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
  }

  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
    transform: translate3d(-5px, 0, 0);
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes bounceInRight {

  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  from {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
    transform: translate3d(3000px, 0, 0);
  }

  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
    transform: translate3d(-25px, 0, 0);
  }

  75% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
  }

  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
    transform: translate3d(-5px, 0, 0);
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.bounceInRight {
  -webkit-animation-name: bounceInRight;
  animation-name: bounceInRight;
}

@-webkit-keyframes bounceInUp {

  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
    transform: translate3d(0, 3000px, 0);
  }

  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }

  75% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
  }

  90% {
    -webkit-transform: translate3d(0, -5px, 0);
    transform: translate3d(0, -5px, 0);
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes bounceInUp {

  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
    transform: translate3d(0, 3000px, 0);
  }

  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }

  75% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
  }

  90% {
    -webkit-transform: translate3d(0, -5px, 0);
    transform: translate3d(0, -5px, 0);
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.bounceInUp {
  -webkit-animation-name: bounceInUp;
  animation-name: bounceInUp;
}

@-webkit-keyframes bounceOut {
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }

  50%,
  55% {
    opacity: 1;
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }

  to {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
}

@keyframes bounceOut {
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }

  50%,
  55% {
    opacity: 1;
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }

  to {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
}

.bounceOut {
  -webkit-animation-duration: 0.75s;
  animation-duration: 0.75s;
  -webkit-animation-name: bounceOut;
  animation-name: bounceOut;
}

@-webkit-keyframes bounceOutDown {
  20% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
  }

  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0);
  }
}

@keyframes bounceOutDown {
  20% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
  }

  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0);
  }
}

.bounceOutDown {
  -webkit-animation-name: bounceOutDown;
  animation-name: bounceOutDown;
}

@-webkit-keyframes bounceOutLeft {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(20px, 0, 0);
    transform: translate3d(20px, 0, 0);
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0);
  }
}

@keyframes bounceOutLeft {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(20px, 0, 0);
    transform: translate3d(20px, 0, 0);
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0);
  }
}

.bounceOutLeft {
  -webkit-animation-name: bounceOutLeft;
  animation-name: bounceOutLeft;
}

@-webkit-keyframes bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
    transform: translate3d(-20px, 0, 0);
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0);
  }
}

@keyframes bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
    transform: translate3d(-20px, 0, 0);
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0);
  }
}

.bounceOutRight {
  -webkit-animation-name: bounceOutRight;
  animation-name: bounceOutRight;
}

@-webkit-keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
  }

  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
    transform: translate3d(0, 20px, 0);
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0);
  }
}

@keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
  }

  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
    transform: translate3d(0, 20px, 0);
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0);
  }
}

.bounceOutUp {
  -webkit-animation-name: bounceOutUp;
  animation-name: bounceOutUp;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
}

@-webkit-keyframes fadeInDownBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInDownBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInDownBig {
  -webkit-animation-name: fadeInDownBig;
  animation-name: fadeInDownBig;
}

@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
}

@-webkit-keyframes fadeInLeftBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInLeftBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInLeftBig {
  -webkit-animation-name: fadeInLeftBig;
  animation-name: fadeInLeftBig;
}

@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
}

@-webkit-keyframes fadeInRightBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInRightBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInRightBig {
  -webkit-animation-name: fadeInRightBig;
  animation-name: fadeInRightBig;
}

@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

@-webkit-keyframes fadeInUpBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInUpBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInUpBig {
  -webkit-animation-name: fadeInUpBig;
  animation-name: fadeInUpBig;
}

@-webkit-keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}

@-webkit-keyframes fadeOutDown {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}

@keyframes fadeOutDown {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}

.fadeOutDown {
  -webkit-animation-name: fadeOutDown;
  animation-name: fadeOutDown;
}

@-webkit-keyframes fadeOutDownBig {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0);
  }
}

@keyframes fadeOutDownBig {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0);
  }
}

.fadeOutDownBig {
  -webkit-animation-name: fadeOutDownBig;
  animation-name: fadeOutDownBig;
}

@-webkit-keyframes fadeOutLeft {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes fadeOutLeft {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

.fadeOutLeft {
  -webkit-animation-name: fadeOutLeft;
  animation-name: fadeOutLeft;
}

@-webkit-keyframes fadeOutLeftBig {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0);
  }
}

@keyframes fadeOutLeftBig {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0);
  }
}

.fadeOutLeftBig {
  -webkit-animation-name: fadeOutLeftBig;
  animation-name: fadeOutLeftBig;
}

@-webkit-keyframes fadeOutRight {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}

@keyframes fadeOutRight {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}

.fadeOutRight {
  -webkit-animation-name: fadeOutRight;
  animation-name: fadeOutRight;
}

@-webkit-keyframes fadeOutRightBig {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0);
  }
}

@keyframes fadeOutRightBig {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0);
  }
}

.fadeOutRightBig {
  -webkit-animation-name: fadeOutRightBig;
  animation-name: fadeOutRightBig;
}

@-webkit-keyframes fadeOutUp {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}

@keyframes fadeOutUp {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}

.fadeOutUp {
  -webkit-animation-name: fadeOutUp;
  animation-name: fadeOutUp;
}

@-webkit-keyframes fadeOutUpBig {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0);
  }
}

@keyframes fadeOutUpBig {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0);
  }
}

.fadeOutUpBig {
  -webkit-animation-name: fadeOutUpBig;
  animation-name: fadeOutUpBig;
}

@-webkit-keyframes flip {
  from {
    -webkit-transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 0) rotate3d(0, 1, 0, -360deg);
    transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 0) rotate3d(0, 1, 0, -360deg);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }

  40% {
    -webkit-transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
    transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }

  50% {
    -webkit-transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
    transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  80% {
    -webkit-transform: perspective(400px) scale3d(0.95, 0.95, 0.95) translate3d(0, 0, 0) rotate3d(0, 1, 0, 0deg);
    transform: perspective(400px) scale3d(0.95, 0.95, 0.95) translate3d(0, 0, 0) rotate3d(0, 1, 0, 0deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  to {
    -webkit-transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 0) rotate3d(0, 1, 0, 0deg);
    transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 0) rotate3d(0, 1, 0, 0deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
}

@keyframes flip {
  from {
    -webkit-transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 0) rotate3d(0, 1, 0, -360deg);
    transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 0) rotate3d(0, 1, 0, -360deg);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }

  40% {
    -webkit-transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
    transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }

  50% {
    -webkit-transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
    transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  80% {
    -webkit-transform: perspective(400px) scale3d(0.95, 0.95, 0.95) translate3d(0, 0, 0) rotate3d(0, 1, 0, 0deg);
    transform: perspective(400px) scale3d(0.95, 0.95, 0.95) translate3d(0, 0, 0) rotate3d(0, 1, 0, 0deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  to {
    -webkit-transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 0) rotate3d(0, 1, 0, 0deg);
    transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 0) rotate3d(0, 1, 0, 0deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
}

.animated.flip {
  -webkit-backface-visibility: visible;
  backface-visibility: visible;
  -webkit-animation-name: flip;
  animation-name: flip;
}

@-webkit-keyframes flipInX {
  from {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }

  40% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  60% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1;
  }

  80% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
  }

  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
}

@keyframes flipInX {
  from {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }

  40% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  60% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1;
  }

  80% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
  }

  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
}

.flipInX {
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
  -webkit-animation-name: flipInX;
  animation-name: flipInX;
}

@-webkit-keyframes flipInY {
  from {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }

  40% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  60% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    opacity: 1;
  }

  80% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
  }

  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
}

@keyframes flipInY {
  from {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }

  40% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  60% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    opacity: 1;
  }

  80% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
  }

  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
}

.flipInY {
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
  -webkit-animation-name: flipInY;
  animation-name: flipInY;
}

@-webkit-keyframes flipOutX {
  from {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }

  30% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity: 1;
  }

  to {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0;
  }
}

@keyframes flipOutX {
  from {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }

  30% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity: 1;
  }

  to {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0;
  }
}

.flipOutX {
  -webkit-animation-duration: 0.75s;
  animation-duration: 0.75s;
  -webkit-animation-name: flipOutX;
  animation-name: flipOutX;
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
}

@-webkit-keyframes flipOutY {
  from {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }

  30% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
    opacity: 1;
  }

  to {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    opacity: 0;
  }
}

@keyframes flipOutY {
  from {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }

  30% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
    opacity: 1;
  }

  to {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    opacity: 0;
  }
}

.flipOutY {
  -webkit-animation-duration: 0.75s;
  animation-duration: 0.75s;
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
  -webkit-animation-name: flipOutY;
  animation-name: flipOutY;
}

@-webkit-keyframes lightSpeedIn {
  from {
    -webkit-transform: translate3d(100%, 0, 0) skewX(-30deg);
    transform: translate3d(100%, 0, 0) skewX(-30deg);
    opacity: 0;
  }

  60% {
    -webkit-transform: skewX(20deg);
    transform: skewX(20deg);
    opacity: 1;
  }

  80% {
    -webkit-transform: skewX(-5deg);
    transform: skewX(-5deg);
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes lightSpeedIn {
  from {
    -webkit-transform: translate3d(100%, 0, 0) skewX(-30deg);
    transform: translate3d(100%, 0, 0) skewX(-30deg);
    opacity: 0;
  }

  60% {
    -webkit-transform: skewX(20deg);
    transform: skewX(20deg);
    opacity: 1;
  }

  80% {
    -webkit-transform: skewX(-5deg);
    transform: skewX(-5deg);
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.lightSpeedIn {
  -webkit-animation-name: lightSpeedIn;
  animation-name: lightSpeedIn;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
}

@-webkit-keyframes lightSpeedOut {
  from {
    opacity: 1;
  }

  to {
    -webkit-transform: translate3d(100%, 0, 0) skewX(30deg);
    transform: translate3d(100%, 0, 0) skewX(30deg);
    opacity: 0;
  }
}

@keyframes lightSpeedOut {
  from {
    opacity: 1;
  }

  to {
    -webkit-transform: translate3d(100%, 0, 0) skewX(30deg);
    transform: translate3d(100%, 0, 0) skewX(30deg);
    opacity: 0;
  }
}

.lightSpeedOut {
  -webkit-animation-name: lightSpeedOut;
  animation-name: lightSpeedOut;
  -webkit-animation-timing-function: ease-in;
  animation-timing-function: ease-in;
}

@-webkit-keyframes rotateIn {
  from {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate3d(0, 0, 1, -200deg);
    transform: rotate3d(0, 0, 1, -200deg);
    opacity: 0;
  }

  to {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes rotateIn {
  from {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate3d(0, 0, 1, -200deg);
    transform: rotate3d(0, 0, 1, -200deg);
    opacity: 0;
  }

  to {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

.rotateIn {
  -webkit-animation-name: rotateIn;
  animation-name: rotateIn;
}

@-webkit-keyframes rotateInDownLeft {
  from {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0;
  }

  to {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes rotateInDownLeft {
  from {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0;
  }

  to {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

.rotateInDownLeft {
  -webkit-animation-name: rotateInDownLeft;
  animation-name: rotateInDownLeft;
}

@-webkit-keyframes rotateInDownRight {
  from {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0;
  }

  to {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes rotateInDownRight {
  from {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0;
  }

  to {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

.rotateInDownRight {
  -webkit-animation-name: rotateInDownRight;
  animation-name: rotateInDownRight;
}

@-webkit-keyframes rotateInUpLeft {
  from {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0;
  }

  to {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes rotateInUpLeft {
  from {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0;
  }

  to {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

.rotateInUpLeft {
  -webkit-animation-name: rotateInUpLeft;
  animation-name: rotateInUpLeft;
}

@-webkit-keyframes rotateInUpRight {
  from {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, -90deg);
    transform: rotate3d(0, 0, 1, -90deg);
    opacity: 0;
  }

  to {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes rotateInUpRight {
  from {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, -90deg);
    transform: rotate3d(0, 0, 1, -90deg);
    opacity: 0;
  }

  to {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

.rotateInUpRight {
  -webkit-animation-name: rotateInUpRight;
  animation-name: rotateInUpRight;
}

@-webkit-keyframes rotateOut {
  from {
    -webkit-transform-origin: center;
    transform-origin: center;
    opacity: 1;
  }

  to {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate3d(0, 0, 1, 200deg);
    transform: rotate3d(0, 0, 1, 200deg);
    opacity: 0;
  }
}

@keyframes rotateOut {
  from {
    -webkit-transform-origin: center;
    transform-origin: center;
    opacity: 1;
  }

  to {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate3d(0, 0, 1, 200deg);
    transform: rotate3d(0, 0, 1, 200deg);
    opacity: 0;
  }
}

.rotateOut {
  -webkit-animation-name: rotateOut;
  animation-name: rotateOut;
}

@-webkit-keyframes rotateOutDownLeft {
  from {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    opacity: 1;
  }

  to {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0;
  }
}

@keyframes rotateOutDownLeft {
  from {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    opacity: 1;
  }

  to {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0;
  }
}

.rotateOutDownLeft {
  -webkit-animation-name: rotateOutDownLeft;
  animation-name: rotateOutDownLeft;
}

@-webkit-keyframes rotateOutDownRight {
  from {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    opacity: 1;
  }

  to {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0;
  }
}

@keyframes rotateOutDownRight {
  from {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    opacity: 1;
  }

  to {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0;
  }
}

.rotateOutDownRight {
  -webkit-animation-name: rotateOutDownRight;
  animation-name: rotateOutDownRight;
}

@-webkit-keyframes rotateOutUpLeft {
  from {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    opacity: 1;
  }

  to {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0;
  }
}

@keyframes rotateOutUpLeft {
  from {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    opacity: 1;
  }

  to {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0;
  }
}

.rotateOutUpLeft {
  -webkit-animation-name: rotateOutUpLeft;
  animation-name: rotateOutUpLeft;
}

@-webkit-keyframes rotateOutUpRight {
  from {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    opacity: 1;
  }

  to {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, 90deg);
    transform: rotate3d(0, 0, 1, 90deg);
    opacity: 0;
  }
}

@keyframes rotateOutUpRight {
  from {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    opacity: 1;
  }

  to {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, 90deg);
    transform: rotate3d(0, 0, 1, 90deg);
    opacity: 0;
  }
}

.rotateOutUpRight {
  -webkit-animation-name: rotateOutUpRight;
  animation-name: rotateOutUpRight;
}

@-webkit-keyframes hinge {
  0% {
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }

  20%,
  60% {
    -webkit-transform: rotate3d(0, 0, 1, 80deg);
    transform: rotate3d(0, 0, 1, 80deg);
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }

  40%,
  80% {
    -webkit-transform: rotate3d(0, 0, 1, 60deg);
    transform: rotate3d(0, 0, 1, 60deg);
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    opacity: 1;
  }

  to {
    -webkit-transform: translate3d(0, 700px, 0);
    transform: translate3d(0, 700px, 0);
    opacity: 0;
  }
}

@keyframes hinge {
  0% {
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }

  20%,
  60% {
    -webkit-transform: rotate3d(0, 0, 1, 80deg);
    transform: rotate3d(0, 0, 1, 80deg);
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }

  40%,
  80% {
    -webkit-transform: rotate3d(0, 0, 1, 60deg);
    transform: rotate3d(0, 0, 1, 60deg);
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    opacity: 1;
  }

  to {
    -webkit-transform: translate3d(0, 700px, 0);
    transform: translate3d(0, 700px, 0);
    opacity: 0;
  }
}

.hinge {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-name: hinge;
  animation-name: hinge;
}

@-webkit-keyframes jackInTheBox {
  from {
    opacity: 0;
    -webkit-transform: scale(0.1) rotate(30deg);
    transform: scale(0.1) rotate(30deg);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
  }

  50% {
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }

  70% {
    -webkit-transform: rotate(3deg);
    transform: rotate(3deg);
  }

  to {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes jackInTheBox {
  from {
    opacity: 0;
    -webkit-transform: scale(0.1) rotate(30deg);
    transform: scale(0.1) rotate(30deg);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
  }

  50% {
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }

  70% {
    -webkit-transform: rotate(3deg);
    transform: rotate(3deg);
  }

  to {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.jackInTheBox {
  -webkit-animation-name: jackInTheBox;
  animation-name: jackInTheBox;
}

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */

@-webkit-keyframes rollIn {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
    transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes rollIn {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
    transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.rollIn {
  -webkit-animation-name: rollIn;
  animation-name: rollIn;
}

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */

@-webkit-keyframes rollOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg);
    transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg);
  }
}

@keyframes rollOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg);
    transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg);
  }
}

.rollOut {
  -webkit-animation-name: rollOut;
  animation-name: rollOut;
}

@-webkit-keyframes zoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }

  50% {
    opacity: 1;
  }
}

@keyframes zoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }

  50% {
    opacity: 1;
  }
}

.zoomIn {
  -webkit-animation-name: zoomIn;
  animation-name: zoomIn;
}

@-webkit-keyframes zoomInDown {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}

@keyframes zoomInDown {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}

.zoomInDown {
  -webkit-animation-name: zoomInDown;
  animation-name: zoomInDown;
}

@-webkit-keyframes zoomInLeft {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}

@keyframes zoomInLeft {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}

.zoomInLeft {
  -webkit-animation-name: zoomInLeft;
  animation-name: zoomInLeft;
}

@-webkit-keyframes zoomInRight {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}

@keyframes zoomInRight {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}

.zoomInRight {
  -webkit-animation-name: zoomInRight;
  animation-name: zoomInRight;
}

@-webkit-keyframes zoomInUp {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}

@keyframes zoomInUp {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}

.zoomInUp {
  -webkit-animation-name: zoomInUp;
  animation-name: zoomInUp;
}

@-webkit-keyframes zoomOut {
  from {
    opacity: 1;
  }

  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }

  to {
    opacity: 0;
  }
}

@keyframes zoomOut {
  from {
    opacity: 1;
  }

  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }

  to {
    opacity: 0;
  }
}

.zoomOut {
  -webkit-animation-name: zoomOut;
  animation-name: zoomOut;
}

@-webkit-keyframes zoomOutDown {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  to {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}

@keyframes zoomOutDown {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  to {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}

.zoomOutDown {
  -webkit-animation-name: zoomOutDown;
  animation-name: zoomOutDown;
}

@-webkit-keyframes zoomOutLeft {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
  }

  to {
    opacity: 0;
    -webkit-transform: scale(0.1) translate3d(-2000px, 0, 0);
    transform: scale(0.1) translate3d(-2000px, 0, 0);
    -webkit-transform-origin: left center;
    transform-origin: left center;
  }
}

@keyframes zoomOutLeft {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
  }

  to {
    opacity: 0;
    -webkit-transform: scale(0.1) translate3d(-2000px, 0, 0);
    transform: scale(0.1) translate3d(-2000px, 0, 0);
    -webkit-transform-origin: left center;
    transform-origin: left center;
  }
}

.zoomOutLeft {
  -webkit-animation-name: zoomOutLeft;
  animation-name: zoomOutLeft;
}

@-webkit-keyframes zoomOutRight {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
  }

  to {
    opacity: 0;
    -webkit-transform: scale(0.1) translate3d(2000px, 0, 0);
    transform: scale(0.1) translate3d(2000px, 0, 0);
    -webkit-transform-origin: right center;
    transform-origin: right center;
  }
}

@keyframes zoomOutRight {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
  }

  to {
    opacity: 0;
    -webkit-transform: scale(0.1) translate3d(2000px, 0, 0);
    transform: scale(0.1) translate3d(2000px, 0, 0);
    -webkit-transform-origin: right center;
    transform-origin: right center;
  }
}

.zoomOutRight {
  -webkit-animation-name: zoomOutRight;
  animation-name: zoomOutRight;
}

@-webkit-keyframes zoomOutUp {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  to {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}

@keyframes zoomOutUp {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  to {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}

.zoomOutUp {
  -webkit-animation-name: zoomOutUp;
  animation-name: zoomOutUp;
}

@-webkit-keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.slideInDown {
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown;
}

@-webkit-keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.slideInLeft {
  -webkit-animation-name: slideInLeft;
  animation-name: slideInLeft;
}

@-webkit-keyframes slideInRight {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideInRight {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.slideInRight {
  -webkit-animation-name: slideInRight;
  animation-name: slideInRight;
}

@-webkit-keyframes slideInUp {
  from {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideInUp {
  from {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.slideInUp {
  -webkit-animation-name: slideInUp;
  animation-name: slideInUp;
}

@-webkit-keyframes slideOutDown {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}

@keyframes slideOutDown {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}

.slideOutDown {
  -webkit-animation-name: slideOutDown;
  animation-name: slideOutDown;
}

@-webkit-keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

.slideOutLeft {
  -webkit-animation-name: slideOutLeft;
  animation-name: slideOutLeft;
}

@-webkit-keyframes slideOutRight {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}

@keyframes slideOutRight {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}

.slideOutRight {
  -webkit-animation-name: slideOutRight;
  animation-name: slideOutRight;
}

@-webkit-keyframes slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}

@keyframes slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}

.slideOutUp {
  -webkit-animation-name: slideOutUp;
  animation-name: slideOutUp;
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.animated.infinite {
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.animated.delay-1s {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

.animated.delay-2s {
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}

.animated.delay-3s {
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
}

.animated.delay-4s {
  -webkit-animation-delay: 4s;
  animation-delay: 4s;
}

.animated.delay-5s {
  -webkit-animation-delay: 5s;
  animation-delay: 5s;
}

.animated.fast {
  -webkit-animation-duration: 800ms;
  animation-duration: 800ms;
}

.animated.faster {
  -webkit-animation-duration: 500ms;
  animation-duration: 500ms;
}

.animated.slow {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
}

.animated.slower {
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
}

/************************** ANIMATION CSS ENDS **************************/


/*short-modal*/

.short-modal {
  &::part(content) {
    position: absolute;
    display: block;
    top: calc(100% - (450px));
    --height: 450px;
    --border-radius: 10px 10px 0px 0px;
    --box-shadow: 0px -0.5px 2px 1px rgba(0, 0, 0, 0.1);
  }

  .modal-shadow {
    position: absolute;
    display: block;
    top: calc(100% - (450px));
    --height: 450px;
  }
}

.user-modal {
  &::part(content) {
    position: absolute;
    display: block;
    --height: 300px;
    --width: 400px;
    --border-radius: 10px 10px 10px 10px;
    --box-shadow: 0px -0.5px 2px 1px rgba(0, 0, 0, 0.1);
  }

  .modal-shadow {
    position: absolute;
    display: block;
    --height: 300px;
    --width: 400px;
  }
}

.app-upgrade-modal {
  &::part(content) {
    position: absolute;
    display: block;
    top: calc(100% - (650px));
    --height: 650px;
    --border-radius: 10px 10px 0px 0px;
    --box-shadow: 0px -0.5px 2px 1px rgba(0, 0, 0, 0.1);
  }

  .modal-shadow {
    position: absolute;
    display: block;
    top: calc(100% - (650px));
    --height: 650px;
  }
}


.disable-page {
  pointer-events: none;
}

.ion-spinner-size {
  transform: scale(3) !important;
}

.ion-spinner-size-block {
  transform: scale(1) !important;
}

.walkthrough-container {
  font-family: 'hylo-font-regular', sans-serif !important;
}

.walkthrough-text {
  font-family: 'hylo-font-regular', sans-serif !important;
  font-size: 16px !important;
  width: 80% !important;
}

.walkthrough-done-button {
  background-color: #5e5be5 !important;
  border-color: #5e5be5 !important;
  border: 0px solid #5e5be5 !important;
  border-radius: 6px !important;
  font-weight: bold !important;
}

.offer-wrapper {
  width: 100%;
  height: 220px;
  overflow: hidden;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  justify-content: flex-start;
}

.offers-list {
  list-style-type: none;
  padding: 0;
  margin-top: 0px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  height: 200px;
}

.offers-list-item {

  margin-right: 10px;
  font-weight: normal;
  border-bottom: 0px solid transparent;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;

  a {
    text-align: center;
  }


}

.offers-list-item:first-child {
  padding-left: 0px;
}

.service-wrapper {
  width: 100%;
  height: 230px;
  overflow: hidden;
  padding-top: 10px;
  padding-bottom: 170px;
  display: flex;
  justify-content: flex-start;
}

.service {
  list-style-type: none;
  padding: 0;
  margin-top: 0px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  height: 200px;
}


.service-item {

  margin-right: 20px;
  font-weight: normal;
  height: 160px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  a {
    text-align: center;
  }


}

.service-item:first-child {
  padding-left: 0px;
}

.service-outer {
  width: 180px;
  height: 160px;
  padding: 12px;
  background: var(--ion-color-primary);
  border-radius: 6px;
  word-wrap: break-word;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #fff;
  cursor: pointer;
}

.service-outer:hover {
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.35) !important;
}

.service-icon {
  width: 36px;
  height: 36px;
}

.service-wrapper-quicklinks {
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: flex-start;
}

.service-quicklinks {
  list-style-type: none;
  display: flex;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  width: 100%;
}

.service-item-quicklinks {
  margin-right: 10px;
  width: 100%;
}

.service-outer-quicklinks {
  width: 100%;
  min-width: 180px;
  padding: 6px;
  background: var(--ion-color-white);
  border-radius: 32px;
  word-wrap: break-word;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.service-item-quicklinks:first-child {
  margin-left: -30px;
}

.service-erp-outer {
  width: 40px;
  height: 40px;
  background: #3c1053;
  border-radius: 50%;
  text-align: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 6px;
}

.service-erp-outer::after {
  clear: both;
  content: '';
  display: block;
  width: 60%;
  height: 3px;
  border-radius: 32px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.service-erp-icon {
  width: 28px;
  height: 28px;
  margin-top: 10px;
}

.service-erp-tally-icon {
  width: 36px;
  margin-top: 10px;
  -webkit-filter: brightness(0) invert(1);
  /* Safari 6.0 - 9.0 */
  filter: brightness(0) invert(1);
}


.service-erp-qb-outer {
  width: 40px;
  height: 40px;
  background: #2ca01c;
  border-radius: 50%;
  text-align: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 6px;
}

.service-erp-qb-outer::after {
  clear: both;
  content: '';
  display: block;
  width: 60%;
  height: 3px;
  border-radius: 32px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.service-erp-qb-icon {
  width: 24px;
  height: 24px;
  margin-top: 8px;
}

.service-erp-zoho-outer {
  width: 40px;
  height: 40px;
  background: #3d0f9f;
  border-radius: 50%;
  text-align: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 6px;
}

.service-erp-zoho-outer::after {
  clear: both;
  content: '';
  display: block;
  width: 60%;
  height: 3px;
  border-radius: 32px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.service-erp-zoho-icon {
  width: 32px;
  height: 12px;
  margin-top: 14px;
}

.service-erp-innoventry-outer {
  width: 40px;
  height: 40px;
  background: #F9A825;
  border-radius: 50%;
  text-align: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 6px;
}

.service-erp-innoventry-outer::after {
  clear: both;
  content: '';
  display: block;
  width: 60%;
  height: 3px;
  border-radius: 32px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.service-erp-innoventry-icon {
  width: 32px;
  margin-top: 18px;
}

:focus {
  outline: none !important;
}


.service-erp-botree-outer {
  width: 48px;
  height: 48px;
  background: #e9f7f3;
  border-radius: 50%;
  text-align: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 16px;
}

.service-erp-botree-outer::after {
  clear: both;
  content: '';
  display: block;
  width: 60%;
  height: 3px;
  border-radius: 32px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.service-erp-botree-icon {
  width: 36px;
  margin-top: 22px;
}

.connected {
  width: 32px;
  height: 32px;
}

.bg-innoventry {
  background: #F9A825 !important;
  color: #fff !important;
}

.bg-botree {
  background: #e9f7f3 !important;
  color: #1C1C1E !important;
}

.transparent-badge {
  background-color: rgba(0, 0, 0, .3);
}

.connected-erp-innoventry {
  width: 100px;
}

.connected-erp-botree {
  width: 140px;
}

.erp-cards {
  width: 320px;
  min-height: 200px;
}

.card-zoho {
  background: #3d0f9f !important;
  color: #fff !important;
}

.zoho-card-logo {
  width: 100px;
}

.card-qb {
  background: #2ca01c !important;
  color: #fff !important;
}

.qb-card-logo {
  width: 48px;
}

.card-innoventry {
  background: #F9A825 !important;
  color: #fff !important;
}

.innoventry-card-logo {
  width: 128px;
}

.card-botree {
  background: #e9f7f3 !important;
  color: #1C1C1E !important;
}

.botree-card-logo {
  width: 128px;
}

.error-messages {
  color: red;
}

.tot-amount-hr {
  height: 1px;
  background: var(--ion-color-medium);
  border-radius: 100px;
}

.txn-card-heading:after {
  content: '';
  display: block;
  margin-top: 6px;
  width: 40px;
  height: 3px;
  background: var(--ion-color-fusion-theme);
  border-radius: 32px;

}

.border-left-home-page-headings {
  border-left: 5px solid var(--ion-color-primary);
}

.bg-tally {
  background: url("../assets/images/backgrounds/large-triangles-tally.svg");
  background-color: #3069b3;
  color: #fff !important;
}

.connected-erp-tally {
  width: 70px;
  -webkit-filter: brightness(0) invert(1);
  /* Safari 6.0 - 9.0 */
  filter: brightness(0) invert(1);
}

.bg-qb {
  background: url("../assets/images/backgrounds/large-triangles-qb.svg");
  background-color: #2ca01c;
  color: #fff !important;
}

.bg-zoho {
  background: url("../assets/images/backgrounds/large-triangles-zoho.svg");
  background-color: #3d0f9f;
  color: #fff !important;
}

.bg-innoventry {
  background: #F9A825 !important;
  color: #fff !important;
}

.connected-erp-qb {
  width: 24px;
}

.connected-erp-zoho {
  width: 80px;
}

.connected-erp-innoventry {
  width: 100px;
}

.transparent-badge {
  background-color: rgba(0, 0, 0, .15);
}

.card-light-shadow {
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.2) !important;
  transition: -webkit-filter .3s ease !important;
  transition: filter .3s ease !important;
  transition: filter .3s ease, -webkit-filter .3s ease !important;
  opacity: 1 !important;
}

.custom-toggle {
  --handle-height: 13px;
  --handle-width: 13px;
  --handle-box-shadow: none;
  --handle-background-checked: var(--ion-color-primary-contrast);
  --handle-background: var(--ion-color-white);
  --border-radius: 0px;
  --handle-border-radius: 0px;
}

.toggle-icon {
  width: 36px !important;
  height: 18px !important;
}

.custom-small-toggle {
  --handle-height: 11px;
  --handle-width: 11px;
  --handle-box-shadow: none;
  --handle-background-checked: var(--ion-color-primary-contrast);
  --handle-background: var(--ion-color-white);
  --border-radius: 0px;
  --handle-border-radius: 0px;
}

.toggle-small-icon {
  width: 28px !important;
  height: 14px !important;
}

.custom-darkMode-toggle {
  --handle-height: 12px;
  --handle-width: 12px;
  --handle-box-shadow: none;
  --handle-background-checked: var(--ion-color-primary);
  --handle-background: var(--ion-color-primary);
  --border-radius: 0px;
  --handle-border-radius: 0px;
}

.toggle-darkMode-icon {
  width: 30px !important;
  height: 15px !important;
}

.big-font-size {
  font-size: 24px !important;
}

.medium-font-size {
  font-size: 16px !important;
}

.small-font-size {
  font-size: 12px !important;
}

.top-header-background {
  display: flex;
  flex: 1;
  flex-direction: row;
  background: var(--ion-color-primary);
  width: 100%;
  height: 160px;
}

ion-segment-button {

  --background-checked: transparent !important;
  // --background-focused: var(--ion-color-primary) !important;
  // --indicator-box-shadow: var(--ion-color-primary) !important;
  //--indicator-color: var(--ion-color-primary) !important;
  // --color-checked: #ffffff !important;

}

.img-shadow {
  -webkit-filter: drop-shadow(5px 5px 5px var(--ion-color-white));
  filter: drop-shadow(5px 5px 5px var(--ion-color-white));

}

.tally-outer {

  width: 280px;
  height: 120px;
  padding: 12px;
  background: url("../assets/images/backgrounds/large-triangles-tally.svg");
  background-color: #3069b3;
  background-size: cover;
  border-radius: 6px;
  word-wrap: break-word;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #fff;
  cursor: pointer;

}

.tally-icon {

  width: 61px;
  height: 30px;

}

.zoho-master-icon {
  width: 50px;
  height: 17px;
}

.zoho-outer {

  width: 280px;
  height: 120px;
  padding: 12px;
  background: url("../assets/images/backgrounds/large-triangles-zoho.svg");
  background-color: #195ea3;
  background-size: cover;
  border-radius: 6px;
  word-wrap: break-word;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #fff;
  cursor: pointer;
}

.zoho-icon {

  width: 16px;
  height: 16px;

}

.zoho-invoice-outer {

  width: 280px;
  height: 120px;
  padding: 12px;
  background: url("../assets/images/backgrounds/large-triangles-invoice.svg");
  background-color: #195ea3;
  background-size: cover;
  border-radius: 6px;
  word-wrap: break-word;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #fff;
  cursor: pointer;

}

.zoho-invoice-icon {

  width: 16px;
  height: 16px;

}

.qb-outer {

  width: 280px;
  height: 120px;
  padding: 12px;
  background: url("../assets/images/backgrounds/large-triangles-qb.svg");
  background-color: #108000;
  background-size: cover;
  border-radius: 6px;
  word-wrap: break-word;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #fff;
  cursor: pointer;

}

.qb-icon {

  width: 120px;

}

.innoventry-outer {

  width: 280px;
  height: 120px;
  padding: 12px;
  background: url("../assets/images/backgrounds/large-triangles-innoventry.svg");
  background-color: #108000;
  background-size: cover;
  border-radius: 6px;
  word-wrap: break-word;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #fff;
  cursor: pointer;

}

.innoventry-icon {

  width: 120px;

}

.kotak-mahindra-bank-outer {

  width: 280px;
  height: 120px;
  padding: 12px;
  background: url("../assets/images/backgrounds/slanted-gradient-kotak.svg");
  background-color: #ff0100;
  background-size: cover;
  border-radius: 6px;
  word-wrap: break-word;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #fff;
  cursor: pointer;

}

.kotak-mahindra-bank-icon {

  width: 100px;
  height: 25px;

}

.yes-bank-outer {

  width: 280px;
  height: 120px;
  padding: 12px;
  background: #005291;
  background: url("../assets/images/backgrounds/slanted-gradient-yes.svg");
  background-size: cover;
  border-radius: 6px;
  word-wrap: break-word;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #fff;
  cursor: pointer;

}

.yes-bank-icon {
  width: 80px;
  height: 31px;
}

.axis-bank-outer {

  width: 280px;
  height: 120px;
  padding: 12px;
  background: #97134d;
  background: url("../assets/images/backgrounds/slanted-gradient-axis.svg");
  background-size: cover;
  border-radius: 6px;
  word-wrap: break-word;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #fff;
  cursor: pointer;

}

.axis-bank-icon {
  width: 80px;
  height: 19px;
}

.icici-bank-outer {

  width: 280px;
  height: 120px;
  padding: 12px;
  background: linear-gradient(159.86deg, #f37e20 46%, #ad1e23 85.09%);
  background: url("../assets/images/backgrounds/slanted-gradient-icici.svg");
  background-size: cover;
  border-radius: 6px;
  word-wrap: break-word;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #fff;
  cursor: pointer;

}

.icici-bank-icon {
  width: 100px;
  height: 20px;
}

.bg-primary {
  background: var(--ion-color-primary);
  color: #ffffff;
}

.bg-secondary {
  background: var(--ion-color-secondary);
  color: #ffffff;
}

.bg-chat-incoming {
  background: #fafafa;
}

.bajaj-header {
  background: url('../assets/images/backgrounds/india-3887574_1280.jpg') !important;
  background-size: cover !important;
  height: 120px;
}

.hdfc-header {
  background: url('../assets/images/backgrounds/payment-4334491_1920.jpg') !important;
  background-size: cover !important;
  background-position: 80%;
  height: 120px;
}

.lh-20 {
  line-height: 20px;
}

.lh-0 {
  line-height: 0px;
}

.img-size-32 {
  width: 32px !important;
  height: 32px !important;
}

.flBox {
  display: flex;
  width: 100%;
}

.justify-center {
  justify-content: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.align-center {
  align-items: center;
}

.align-start {
  align-items: flex-start;
}

.align-end {
  align-items: flex-end;
}

.product-advantages-img {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.product-advantages-img-outer {
  display: flex;
  background-color: #fff;
  width: 48px;
  height: 48px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
}

.product-advantages-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-left: 15px;
}



.bajaj-advert-card {
  background: url("../assets/images/promotions/bajaj-advert-card.jpg");
  background-size: contain;
  height: 180px;
  width: 332px;
  border-radius: 6px;
}

.axis-advert-card {
  background: url("../assets/images/promotions/Hylobiz_Seller_Sticky_512x320-56.jpg");
  background-size: contain;
  height: 180px;
  width: 320px;
  border-radius: 6px;
}

.hdfc-advert-card {
  background: url("../assets/images/promotions/app_creative_hylo_hdfc_v1.jpg");
  background-size: contain;
  height: 180px;
  width: 332px;
  border-radius: 6px;
}

.d-block {
  display: block;
}

.d-flex {
  display: flex;
}

.fl-w-100 {
  width: 100%;
}

.fl-w-95 {
  width: 95%;
}

.fl-w-90 {
  width: 90%;
}

.fl-w-80 {
  width: 80%;
}

.fl-w-85 {
  width: 85%;
}

.fl-w-75 {
  width: 75%;
}

.fl-w-70 {
  width: 70%;
}

.fl-w-60 {
  width: 60%;
}

.fl-w-50 {
  width: 50%;
}

.fl-w-40 {
  width: 40%;
}

.fl-w-33 {
  width: 33%;
}

.fl-w-30 {
  width: 30%;
}

.fl-w-25 {
  width: 25%;
}

.fl-w-20 {
  width: 20%;
}

.fl-w-15 {
  width: 15%;
}

.fl-w-10 {
  width: 10%;
}

.fl-w-5 {
  width: 5%;
}

.fl-dir-row {
  flex-direction: row;
}

.fl-dir-col {
  flex-direction: column;
}

.fl-dir-row-reverse {
  flex-direction: row-reverse;
}

.fl-dir-col-reverse {
  flex-direction: column-reverse;
}

.fl-flow-column {
  flex-flow: column;
}

.h-100p {
  height: 100%;
}

.h-56px {
  height: 56px;
}

.h-100vh {
  height: 100vh;
}

.h-50vh {
  height: 50vh;
}

.fs-32 {
  font-size: 32px !important;
}

.fs-28 {
  font-size: 28px !important;
}

.fs-24 {
  font-size: 24px !important;
}

.fs-22 {
  font-size: 22px !important;
}

.fs-18 {
  font-size: 18px !important;
}

.fs-20 {
  font-size: 20px !important;
}

.fs-16 {
  font-size: 16px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-10 {
  font-size: 10px !important;
}

.fs-8 {
  font-size: 8px !important;
}

.scroll-y {
  overflow-y: visible;
}

.ls-1 {
  letter-spacing: 0.5px;
}

.b-b-outer {

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.b-b-outer img {
  width: 24px;
  height: 24px;
}

.toolbar-icon-outer {
  display: flex;
  width: 42px;
  height: 42px;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: var(--ion-color-light);

}

.toolbar-icon-outer img {
  width: 24px;
  height: 24px;
}

.toolbar-icon-outer-browser {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 8px;
  padding: 10px 8px 10px 8px;
  background: var(--ion-color-primary);
}

.toolbar-icon-outer-browser ion-text {
  color: var(--ion-color-white) !important;
}

.toolbar-icon-outer-browser img {
  width: 24px;
  height: 24px;
  -webkit-filter: brightness(0) invert(1);
  filter: brightness(0) invert(1);
}

.toolbar-icon-outer:hover {
  background: var(--ion-color-pending);
}

.toolbar-icon-outer-browser:hover {
  background: var(--ion-color-primary-tint);
}

.toolbar-modal-icon-outer-browser {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 10px;
  padding: 8px;
  border: 1px solid var(--ion-color-light);
}

.toolbar-modal-icon-outer-browser img {
  width: 24px;
  height: 24px;
}

.toolbar-modal-icon-outer-browser:hover {
  background: var(--ion-color-very-light);
}

.toolbar-icon-outer-dc {

  display: flex;
  width: 40px;
  height: 40px;
  border-radius: 100px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: var(--ion-color-danger);

}

.toolbar-icon-outer-dc:hover {
  background: var(--ion-color-danger-shade);
}

.c-pointer {
  cursor: pointer;
}

.d-c-icon-outer {

  display: flex;
  width: 40px;
  height: 40px;
  border-radius: 100px;
  justify-content: center;
  align-items: center;
  background: var(--ion-color-white);

}

.footer-kf-icon-outer {

  display: flex;
  width: 40px;
  height: 40px;
  border-radius: 100px;
  justify-content: center;
  align-items: center;

}

.-ml-1 {
  margin-left: -1px;
}

.-ml-2 {
  margin-left: -2px;
}

.button-shadow {

  border-radius: 50px;
  box-shadow: 0px 12px 20px rgba(0, 0, 0, 0.15);
  transform: translateY(-1px);

}

.dashboard-icon {

  width: 24px;
  height: 24px;
  // -webkit-filter: brightness(0) invert(1);
  // filter: brightness(0) invert(1);

}

.dashboard-icon-logout {

  width: 24px;
  height: 24px;

}

.dashboard-yes-bank-icon {

  width: 24px;
  height: 24px;
  border-radius: 50%;

}

.dashboard-kotak-bank-icon {

  width: 24px;
  height: 24px;
  border-radius: 50%;

}

.dashboard-tally-erp-icon {

  width: 24px;
  height: 24px;
  border-radius: 50%;

}

.r-card-outer {
  position: relative;
  float: right;
  margin-top: -150px;
  margin-right: -5px;
  background: var(--ion-color-receivables-card);
  border: 2px solid var(--ion-color-receivables-card-shade);
  width: 44px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
}

.p-card-outer {
  position: relative;
  float: right;
  margin-top: -150px;
  margin-right: -5px;
  background: var(--ion-color-payables-card);
  border: 2px solid var(--ion-color-payables-card-shade);
  width: 44px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
}

.r-card-outer-large {
  position: relative;
  float: right;
  margin-top: -20px;
  margin-right: -10px;
  width: 44px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  opacity: 0.5;
}

.p-card-outer-large {
  position: relative;
  float: right;
  margin-top: -20px;
  margin-right: -10px;
  width: 44px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  opacity: 0.5;
}

.p-r-card-icons {
  -webkit-filter: brightness(0) invert(1);
  /* Safari 6.0 - 9.0 */
  filter: brightness(0) invert(1);
}

.white-images {
  -webkit-filter: brightness(0) invert(1);
  /* Safari 6.0 - 9.0 */
  filter: brightness(0) invert(1);
}

.bg-receivables-card {
  background: var(--ion-color-receivables-card);
  color: #ffffff;
}

.bg-payables-card {
  background: var(--ion-color-payables-card);
  color: #ffffff;
}

.bg-orange-600 {
  background: #ea580c;
}

.bg-green-600 {
  background: #16a34a;
}

.bg-darkblue-600 {
  background: #005db4;
}

.bg-indigo-600 {
  background: #4f46e5;
}


.bg-red-600 {
  background: #dc2626;
}

.bg-emerald-600 {
  background: #059669;
}

.bg-amber-500 {
  background: #f59e0b;
}

.bg-pink-700 {
  background: #be185d;
}

.bg-amber-svg {
  background: url('../assets/images/backgrounds/tortoise-shell-amber.svg');
  background-size: cover;
}

.bg-pink-svg {
  background: url('../assets/images/backgrounds/tortoise-shell-pink.svg');
  background-size: cover;
}

.bg-darkblue-svg {
  background: url('../assets/images/backgrounds/tortoise-shell-darkblue.svg');
  background-size: cover;
}

.bg-indigo-svg {
  background: url('../assets/images/backgrounds/tortoise-shell-indigo.svg');
  background-size: cover;
}

.bg-indigo900-svg {
  background: url('../assets/images/backgrounds/tortoise-shell-indigo900.svg');
  background-size: cover;
}

.bg-success900-svg {
  background: url('../assets/images/backgrounds/tortoise-shell-success900.svg');
  background-size: cover;
}

.bg-red-svg {
  background: url('../assets/images/backgrounds/tortoise-shell-red.svg');
  background-size: cover;
}

.bg-emerald-svg {
  background: url('../assets/images/backgrounds/tortoise-shell-emerald.svg');
  background-size: cover;
}

.bg-teal-svg {
  background: url('../assets/images/backgrounds/tortoise-shell-teal.svg');
  background-size: cover;
}

.bg-message {
  background: var(--ion-color-primary);
  color: #fff;
}

.bg-whatsApp {
  background: #25D366;
  color: #fff;
}

.m-c-card-sms {
  border-radius: 100%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #e0e7ff;
}

.m-c-card-whatsapp {
  border-radius: 100%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #d1fae5;
}

.einv-c-card {
  border-radius: 100%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f5e7ed;
}

.m-c-card-add-btn {

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 50px;
  height: 25px;
  border-radius: 32px;
  background: var(--ion-color-primary);
  color: #fff;
}

.m-c-card-add-btn:hover {
  background: var(--ion-color-primary-tint);
}

.bg-red-light {
  background: #fef2f2;
}

.bg-green-light {
  background: #f0fdfa;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-pointer-add-underline {
  position: relative;
}

.cursor-pointer-add-underline:hover {
  color: var(--ion-color-primary);
}

.cursor-pointer-add-underline:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  bottom: 0;
  left: 0;
  top: 25px;
  background-color: var(--ion-color-secondary);
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.3s ease-in-out;
}

.cursor-pointer-add-underline:hover:before {
  visibility: visible;
  transform: scaleX(1);
}

.add-bg-on-hover:hover {
  background: var(--ion-color-light);
}

.bg-info-card {
  background: var(--ion-color-white) !important;
  background-size: cover;
  cursor: pointer;
}

.w-h-otp-input {
  width: 10% !important;
  height: 57px !important;
  border: 0px solid transparent !important;
  background: var(--ion-color-white) !important;
  border-radius: 0px !important;
  border: 1px solid var(--ion-color-pending) !important;
}

.w-h-otp-input:focus-within {
  border: 1px solid var(--ion-color-primary) !important;
  transition: all 0.6s linear;
}

.mat-calendar-body-selected {
  background-color: var(--ion-color-primary) !important;
  color: #fff !important;
}

.mat-calendar {
  font-family: "hylobiz-global-font-bold", sans-serif !important;
  background-color: var(--ion-color-fusion-theme) !important;
  border-radius: 12px !important;
}

.mat-datepicker-content {
  border-radius: 12px !important;
}

.mat-calendar-arrow {
  display: inline-block;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top-width: 5px;
  border-top-style: solid;
  margin: 0 0 0 5px;
  vertical-align: middle;
  border-top-color: var(--ion-color-dark);
}

.mat-calendar-body-cell-content {
  color: var(--ion-color-dark);
}

.mat-calendar-table-header th {
  color: var(--ion-color-medium);
  font-family: "hylobiz-global-font-bold", sans-serif;
  font-size: 14px !important;
}

.mat-button,
.mat-flat-button,
.mat-icon-button,
.mat-stroked-button {
  font-family: "hylobiz-global-font-extra-bold", sans-serif;
  color: var(--ion-color-dark);
}

.mat-datepicker-content .mat-calendar-next-button,
.mat-datepicker-content .mat-calendar-previous-button,
.mat-datepicker-toggle {
  color: var(--ion-color-dark);
}

.mat-calendar-body-label {
  font-family: "hylobiz-global-font-extra-bold", sans-serif;
  color: var(--ion-color-medium);
}

.mat-calendar-body-disabled>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
  font-family: "hylobiz-global-font-extra-bold", sans-serif;
  color: var(--ion-color-medium);
}

.mat-button.mat-accent[disabled],
.mat-button.mat-primary[disabled],
.mat-button.mat-warn[disabled],
.mat-button[disabled][disabled],
.mat-icon-button.mat-accent[disabled],
.mat-icon-button.mat-primary[disabled],
.mat-icon-button.mat-warn[disabled],
.mat-icon-button[disabled][disabled],
.mat-stroked-button.mat-accent[disabled],
.mat-stroked-button.mat-primary[disabled],
.mat-stroked-button.mat-warn[disabled],
.mat-stroked-button[disabled][disabled] {
  color: var(--ion-color-medium);
}

.upload-section {
  border: 2px dashed var(--ion-color-medium);
  border-radius: 4px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-transition-delay: 9999s;
  transition-delay: 9999s;
}

.stepper-list {
  position: relative;
  margin: 0 0 0px 0;
  padding: 0;
  color: transparent;
}

.stepper-list:before {
  content: '';
  position: absolute;
  top: 0px;
  bottom: 0px;
  width: 2px;
  background: var(--ion-color-white);
  left: 26px;
  margin: 0;
  border-radius: 16px;
}

.activeMenu {
  background-color: rgba(255, 255, 255, 0.10);
  border-radius: 0px;
  margin: 0px;
}

.inactiveMenu {
  margin: 0px;
}


.txn-wrapper {
  width: 100%;
  height: 220px;
  overflow: hidden;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  justify-content: flex-start;
}

.txn-list {
  list-style-type: none;
  padding: 0;
  margin-top: 0px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  height: 200px;
}


.txn-list-item {
  margin-right: 10px;
  display: flex;
}

.txn-list-item:first-child {
  padding-left: 0px;
}

.txn-list-outer {
  width: 300px;
  border-radius: 6px;
}

.txn-list-icon {
  width: 40px;
  height: 40px;
}


.txn-list::-webkit-scrollbar {
  height: 4px !important;
  display: block;
}

.txn-list::-webkit-scrollbar-track:horizontal {
  background-color: var(--ion-color-fusion-theme) !important;
  border-radius: 32px !important;
}

.txn-list::-webkit-scrollbar-thumb:horizontal {
  border-radius: 32px !important;
  background-color: var(--ion-color-primary) !important;
}

.txn-list::-webkit-scrollbar-track {
  border-radius: 32px !important;

}


@media only screen and (min-width: 320px) and (max-width: 736px) {

  .txn-list::-webkit-scrollbar-track {
    margin: 0 125px !important;
  }
}

@media only screen and (min-width: 737px) and (max-width: 991px) {
  .txn-list::-webkit-scrollbar-track {
    margin: 0 300px !important;
  }
}

@media only screen and (min-width: 992px) and (max-width: 2048px) {

  .txn-list::-webkit-scrollbar-track {
    margin: 0 500px !important;
  }
}

.active-user {
  display: block;
  width: 12px;
  height: 12px;
  background: var(--ion-color-success);
  border-radius: 100%;
  margin-left: -12px;
  margin-top: -36px;
}

.inactive-user {
  display: block;
  width: 12px;
  height: 12px;
  background: var(--ion-color-danger);
  border-radius: 100%;
  margin-left: -12px;
  margin-top: -36px;
}

.text-underline {
  text-decoration: underline;
}

.hoverEffect-medium {
  border: 1px solid transparent;
}

.hoverEffect-medium:hover {
  border: 1px solid var(--ion-color-medium);
}

.bg-warning {
  background: var(--ion-color-warning);
  color: #1C1C1E !important;
}

ion-modal {
  --backdrop-opacity: 0.9 !important;
}

table.dataTable tbody th,
table.dataTable tbody td {
  padding: 24px 10px !important;
  border-bottom: 1px solid var(--ion-color-light) !important;
}

table.dataTable thead th,
table.dataTable thead td {
  padding: 18px 10px !important;
  background: #333333 !important;
  border-bottom: 0px solid rgba(0, 0, 0, 0.3) !important;
  color: #ffffff !important;
}

table.dataTable thead:nth-child(1) {
  border-radius: 5px 0px 0px 0px;
}

table.dataTable.table-striped tbody tr.odd td {
  background-color: var(--ion-color-light) !important;
}

table.dataTable.table-striped tbody tr.even td {
  background-color: var(--ion-color-white) !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.disabled,
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover,
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled:active {
  cursor: default;
  color: #666 !important;
  border: 1px solid transparent;
  background: transparent;
  box-shadow: none;
  font-family: "hylobiz-global-font-bold", sans-serif !important;
  font-size: 14px !important;
}

.dataTables_info {
  font-family: "hylobiz-global-font-bold", sans-serif !important;
  color: var(--ion-color-medium) !important;
  font-size: 14px !important;
}

.dataTables_wrapper .dataTables_paginate {
  float: right;
  text-align: right;
  padding-top: 0.25em !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
  box-sizing: border-box;
  display: inline-block;
  min-width: 1.5em;
  padding: 0.5em 1em;
  margin-left: 2px;
  text-align: center;
  text-decoration: none !important;
  cursor: pointer;
  *cursor: hand;
  border: 0px solid transparent !important;
  border-radius: 0px !important;
  font-family: "hylobiz-global-font-bold", sans-serif !important;
  font-size: 14px !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current {
  background: var(--ion-color-white) !important;
  border: 1px solid var(--ion-color-primary) !important;
}

.paginate_button.previous:before {
  content: '← ';
}

.paginate_button.next:after {
  content: ' →';
}

.dataTables_wrapper .dataTables_filter input {
  border: 1px solid var(--ion-color-medium) !important;
  border-radius: 4px !important;
  padding: 8px !important;
  background-color: transparent;
  margin-left: 3px;
  font-family: "hylobiz-global-font-regular", sans-serif !important;
  font-size: 14px !important;
}

.dataTables_wrapper .dataTables_filter input:focus-within {
  border: 1px solid var(--ion-color-medium-shade) !important;
}



.min-h-140 {
  min-height: 140px;
}

.min-h-120 {
  min-height: 120px;
}

.min-h-100 {
  min-height: 100px;
}

.min-h-70 {
  min-height: 70px;
}

.close-button {
  top: 30px !important;
}

.credit-card {
  border-radius: 10px !important;
  min-height: 280px;
}

.credit-card-img-outer {
  width: 64px;
  height: 64px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--ion-color-white);
}

.opacity-10 {
  opacity: 0.1;
}

.opacity-20 {
  opacity: 0.2;
}

.opacity-30 {
  opacity: 0.3;
}

.opacity-40 {
  opacity: 0.4;
}

.opacity-50 {
  opacity: 0.5;
}

.opacity-60 {
  opacity: 0.6;
}

.opacity-70 {
  opacity: 0.7;
}

.opacity-80 {
  opacity: 0.8;
}

.opacity-90 {
  opacity: 0.9;
}

.opacity-100 {
  opacity: 1;
}

.bank-dashboard-service {
  height: 130px;
}

.arrow-down-icon {
  font-size: 20px !important;
}

.bg-gbs {
  background: linear-gradient(90deg, #d5312c 0%, #a73643 29%, #264282 100%);
  color: #ffffff;
}

.bg-axis {
  background: #96144e;
  color: #ffffff;
}

.bg-axis-light {
  background: #fef0f7;
  color: #96144e;
}

.axis-color {
  color: #96144e;
}

.common-icons {
  width: 24px;
  height: 24px;
}

.bg-offer {
  background: #FDF2D6;
}

.bg-failed {
  background: var(--ion-color-failed);
  color: var(--ion-color-failed-contrast);
}

.bg-paid {
  background: var(--ion-color-paid);
  color: var(--ion-color-paid-contrast);
}

.bg-pending {
  background: var(--ion-color-pending);
  color: var(--ion-color-pending-contrast);
}

.bg-partial {
  background: var(--ion-color-partial);
  color: var(--ion-color-partial-contrast);
}

.bg-draft {
  background: var(--ion-color-draft);
  color: var(--ion-color-draft-contrast);
}

.bg-success {
  background: var(--ion-color-success);
  color: var(--ion-color-success-contrast);
}

.bg-danger {
  background: var(--ion-color-danger);
  color: var(--ion-color-danger-contrast);
}

.bg-warning {
  background: var(--ion-color-warning);
  color: var(--ion-color-warning-contrast);
}

.bg-very-light {
  background: var(--ion-color-very-light);
}

.textHover:hover {
  color: var(--ion-color-primary);
}

.action-icons {
  width: 20px;
  height: 20px;
}

.invoice-item-list {
  border-bottom: 2px solid var(--ion-color-light);
}

.invoice-item-list:last-of-type {
  border-bottom: 2px solid transparent;
}

.invoice-item-list:nth-child(odd) {
  background: var(--ion-color-very-light);
}


.item-list {
  border-bottom: 2px solid var(--ion-color-light);
}

.item-list:last-of-type {
  border-bottom: 2px solid transparent;
}


/********************************* CSS for Dark Mode *********************************/
// body.dark {


//   --ion-color-primary: #6e6be8;
//   --ion-color-primary-rgb: 110, 107, 232;
//   --ion-color-primary-contrast: #ffffff;
//   --ion-color-primary-contrast-rgb: 255, 255, 255;
//   --ion-color-primary-shade: #615ecc;
//   --ion-color-primary-tint: #7d7aea;


//   --ion-color-secondary: #5260ff;
//   --ion-color-secondary-rgb: 82, 96, 255;
//   --ion-color-secondary-contrast: #ffffff;
//   --ion-color-secondary-contrast-rgb: 255, 255, 255;
//   --ion-color-secondary-shade: #4854e0;
//   --ion-color-secondary-tint: #6370ff;

//   --ion-color-tertiary: #5260ff;
//   --ion-color-tertiary-rgb: 82, 96, 255;
//   --ion-color-tertiary-contrast: #ffffff;
//   --ion-color-tertiary-contrast-rgb: 255, 255, 255;
//   --ion-color-tertiary-shade: #4854e0;
//   --ion-color-tertiary-tint: #6370ff;

//   --ion-color-success: #16a34a;
//   --ion-color-success-rgb: 22, 163, 74;
//   --ion-color-success-contrast: #ffffff;
//   --ion-color-success-contrast-rgb: 255, 255, 255;
//   --ion-color-success-shade: #138f41;
//   --ion-color-success-tint: #2dac5c;

//   --ion-color-warning: #FFD600;
//   --ion-color-warning-rgb: 255, 214, 0;
//   --ion-color-warning-contrast: #000000;
//   --ion-color-warning-contrast-rgb: 0, 0, 0;
//   --ion-color-warning-shade: #e0bc00;
//   --ion-color-warning-tint: #ffda1a;

//   --ion-color-danger: #FF453A;
//   --ion-color-danger-rgb: 255, 69, 58;
//   --ion-color-danger-contrast: #ffffff;
//   --ion-color-danger-contrast-rgb: 255, 255, 255;
//   --ion-color-danger-shade: #e03d33;
//   --ion-color-danger-tint: #ff584e;

//   --ion-color-dark: #fafafc;
//   --ion-color-dark-rgb: 250, 250, 252;
//   --ion-color-dark-contrast: #000000;
//   --ion-color-dark-contrast-rgb: 0, 0, 0;
//   --ion-color-dark-shade: #dcdcde;
//   --ion-color-dark-tint: #fbfbfc;


//   --ion-color-medium: #989aa2;
//   --ion-color-medium-rgb: 152, 154, 162;
//   --ion-color-medium-contrast: #000000;
//   --ion-color-medium-contrast-rgb: 0, 0, 0;
//   --ion-color-medium-shade: #86888f;
//   --ion-color-medium-tint: #a2a4ab;

//   --ion-color-light: #1C1C1E;
//   --ion-color-light-rgb: 28, 28, 30;
//   --ion-color-light-contrast: #ffffff;
//   --ion-color-light-contrast-rgb: 255, 255, 255;
//   --ion-color-light-shade: #19191a;
//   --ion-color-light-tint: #242425;

//   --ion-color-white: #000000;
//   --ion-color-white-rgb: 28, 28, 30;
//   --ion-color-white-contrast: #ffffff;
//   --ion-color-white-contrast-rgb: 255, 255, 255;
//   --ion-color-white-shade: #19191a;
//   --ion-color-white-tint: #2e2e30;

//   --ion-color-fusion-theme: #141313;
//   --ion-color-fusion-theme-rgb: 20, 19, 19;
//   --ion-color-fusion-theme-contrast: #ffffff;
//   --ion-color-fusion-theme-contrast-rgb: 255, 255, 255;
//   --ion-color-fusion-theme-shade: #121111;
//   --ion-color-fusion-theme-tint: #2c2b2b;

//   --ion-color-very-light: #141313;
//   --ion-color-very-light-rgb: 20, 19, 19;
//   --ion-color-very-light-contrast: #ffffff;
//   --ion-color-very-light-contrast-rgb: 255, 255, 255;
//   --ion-color-very-light-shade: #121111;
//   --ion-color-very-light-tint: #2c2b2b;

//   --ion-color-receivables-card: #10b981;
//   --ion-color-receivables-card-rgb: 16, 185, 129;
//   --ion-color-receivables-card-contrast: #ffffff;
//   --ion-color-receivables-card-contrast-rgb: 255, 255, 255;
//   --ion-color-receivables-card-shade: #0ea372;
//   --ion-color-receivables-card-tint: #28c08e;

//   --ion-color-payables-card: #ef4444;
//   --ion-color-payables-card-rgb: 239, 68, 68;
//   --ion-color-payables-card-contrast: #ffffff;
//   --ion-color-payables-card-contrast-rgb: 255, 255, 255;
//   --ion-color-payables-card-shade: #d23c3c;
//   --ion-color-payables-card-tint: #f15757;

//   --ion-color-black: #1c1c1E;
//   --ion-color-black-rgb: 28, 28, 30;
//   --ion-color-black-contrast: #ffffff;
//   --ion-color-black-contrast-rgb: 255, 255, 255;
//   --ion-color-black-shade: #19191a;
//   --ion-color-black-tint: #333335;

//   /** Pending Badges **/

//   --ion-color-pending: #d7d5ec;
//   --ion-color-pending-rgb: 215, 213, 236;
//   --ion-color-pending-contrast: #362da2;
//   --ion-color-pending-contrast-rgb: 54, 45, 162;
//   --ion-color-pending-shade: #bdbbd0;
//   --ion-color-pending-tint: #dbd9ee;

//   /** Partial Paid Badges **/

//   --ion-color-partial: #fff5cc;
//   --ion-color-partial-rgb: 255, 245, 204;
//   --ion-color-partial-contrast: #000000;
//   --ion-color-partial-contrast-rgb: 0, 0, 0;
//   --ion-color-partial-shade: #e0d8b4;
//   --ion-color-partial-tint: #fff6d1;

//   /** Paid Badges **/

//   --ion-color-paid: #ccedde;
//   --ion-color-paid-rgb: 204, 237, 222;
//   --ion-color-paid-contrast: #00a75a;
//   --ion-color-paid-contrast-rgb: 5, 150, 105;
//   --ion-color-paid-shade: #b4d1c3;
//   --ion-color-paid-tint: #d1efe1;

//   /** Failed Badges **/

//   --ion-color-failed: #f8d4d4;
//   --ion-color-failed-rgb: 248, 212, 212;
//   --ion-color-failed-contrast: #dc2626;
//   --ion-color-failed-contrast-rgb: 220, 38, 38;
//   --ion-color-failed-shade: #dabbbb;
//   --ion-color-failed-tint: #f9d8d8;

//   .text-black {
//     color: #fff !important;
//   }

//   .text-muted {
//     color: #989aa2 !important;
//   }

//   .text-purple {
//     color: var(--ion-color-primary) !important;
//   }



//   .bg-table-even-background {
//     // background: #252525;
//     background: #1a1a1a;
//   }

//   .bg-chat-incoming {
//     background: #2a2a2a;
//   }

//   .border-white {
//     border-color: #000 !important
//   }


//   .card-header {
//     //background-color: #111111;
//     background-color: rgba(55, 54, 54, .30);
//   }

//   .card-footer {
//     //background-color: #111111;
//     background-color: rgba(55, 54, 54, .30);
//   }

//   .dashboard-icon {
//     -webkit-filter: brightness(0) invert(1);
//     filter: brightness(0) invert(1);
//   }

//   .dashboard-icon-logout {
//     -webkit-filter: brightness(0) invert(1);
//     filter: brightness(0) invert(1);
//   }

//   .brand-logo {
//     -webkit-filter: brightness(0) invert(1);
//     /* Safari 6.0 - 9.0 */
//     filter: brightness(0) invert(1);
//   }

//   .brand-logo-small {
//     -webkit-filter: brightness(0) invert(1) !important;
//     /* Safari 6.0 - 9.0 */
//     filter: brightness(0) invert(1) !important;
//   }

//   .brand-logo-large {
//     -webkit-filter: brightness(0) invert(1) !important;
//     /* Safari 6.0 - 9.0 */
//     filter: brightness(0) invert(1) !important;
//   }


//   .custom-icons {
//     -webkit-filter: brightness(0) invert(1) !important;
//     /* Safari 6.0 - 9.0 */
//     filter: brightness(0) invert(1) !important;
//   }

//   .edit-information {
//     background: rgb(10, 132, 255);
//   }

//   .edit-user-information {
//     background: rgb(48, 209, 88);
//   }

//   .team {
//     background: rgb(94, 92, 230);
//   }

//   .change-password {
//     background: rgb(255, 159, 10);
//   }

//   .home-menu {
//     background: rgb(10, 132, 255);
//   }

//   .receivables-menu {
//     background: rgb(48, 209, 88);
//   }

//   .payables-menu {
//     background: rgb(94, 92, 230);
//   }

//   .ledger-menu {
//     background: rgb(255, 159, 10);
//   }

//   .settings-menu {
//     background: rgb(255, 55, 95);
//   }

//   .stock-house-menu {
//     background: rgb(10, 132, 255);
//   }

//   .purchase-order-menu {
//     background: rgb(94, 92, 230);
//   }

//   .marketplace-menu {
//     background: rgb(79, 70, 229);
//   }

//   .reports-menu {
//     background: rgb(255, 69, 58);
//   }

//   .only-white {
//     background: #000;
//   }

//   .dark-mode {
//     background: rgb(255, 69, 58);
//   }

//   .security-shield {
//     background: rgb(48, 209, 88);
//   }

//   .neumorph-card {
//     background: var(--ion-color-light);
//   }

.inverted-icon {
  -webkit-filter: brightness(0) invert(1);
  /* Safari 6.0 - 9.0 */
  filter: brightness(0) invert(1);
}

//   .border-light-fusion-theme-border {
//     // border: 2px solid #373636 !important;
//     border: 2px solid #121212 !important;
//   }

//   .m-c-card-sms {
//     background: #c7d2fe;
//   }

//   .m-c-card-whatsapp {
//     background: #a7f3d0;
//   }

//   .einv-c-card {
//     background: #e0b8ca;
//   }

//   .bg-red-light {
//     background: #ef4444;
//   }

//   .bg-green-light {
//     background: #059669;
//   }

//   .bg-very-light {
//     background: var(--ion-color-very-light);
//   }

//   .bg-light {
//     background: var(--ion-color-light);
//   }

//   .w-h-otp-input {
//     border: 2px solid transparent !important;
//     background: #141313 !important;
//   }

//   .w-h-otp-input:focus-within {
//     border-bottom: 2px solid #6e6be8 !important;
//     transition: all 0.6s linear;
//   }

//   .border-left-initiated {
//     border-left: 3px solid #989aa2 !important;
//   }

//   .border-left-progress {
//     border-left: 3px solid #F6C645 !important;
//   }

//   .border-left-pending {
//     border-left: 3px solid #6366f1 !important;
//   }

//   .border-left-processed {
//     border-left: 3px solid #16a34a !important;

//   }

//   .border-left-cancel {
//     border-left: 3px solid #D9515E !important;
//   }

//   .timeline>li>.timeline-item>.timeline-header {
//     background-color: rgba(55, 54, 54, .30);
//   }

//   .timeline>li>.timeline-item>.timeline-footer {
//     background-color: rgba(55, 54, 54, .30);
//   }

// }

/*
 * iOS Dark Theme
 * -------------------
 */

// .ios body.dark {
//   --ion-background-color: #000000;
//   --ion-background-color-rgb: 0, 0, 0;
//   --ion-text-color: #ffffff;
//   --ion-text-color-rgb: 255, 255, 255;
//   --ion-color-step-50: #0d0d0d;
//   --ion-color-step-100: #1a1a1a;
//   --ion-color-step-150: #262626;
//   --ion-color-step-200: #333333;
//   --ion-color-step-250: #404040;
//   --ion-color-step-300: #4d4d4d;
//   --ion-color-step-350: #595959;
//   --ion-color-step-400: #666666;
//   --ion-color-step-450: #737373;
//   --ion-color-step-500: #808080;
//   --ion-color-step-550: #8c8c8c;
//   --ion-color-step-600: #999999;
//   --ion-color-step-650: #a6a6a6;
//   --ion-color-step-700: #b3b3b3;
//   --ion-color-step-750: #bfbfbf;
//   --ion-color-step-800: #cccccc;
//   --ion-color-step-850: #d9d9d9;
//   --ion-color-step-900: #e6e6e6;
//   --ion-color-step-950: #f2f2f2;
//   --ion-toolbar-background: #000;
//   --ion-item-background: transparent;
//   --ion-item-background-activated: transparent;

// }

/*
 * Material Design Dark Theme
 * ------------------------------
 */

// .md body.dark {
//   --ion-background-color: #121212;
//   --ion-background-color-rgb: 18, 18, 18;
//   --ion-text-color: #ffffff;
//   --ion-text-color-rgb: 255, 255, 255;
//   --ion-border-color: #222222;
//   --ion-color-step-50: #1e1e1e;
//   --ion-color-step-100: #2a2a2a;
//   --ion-color-step-150: #363636;
//   --ion-color-step-200: #414141;
//   --ion-color-step-250: #4d4d4d;
//   --ion-color-step-300: #595959;
//   --ion-color-step-350: #656565;
//   --ion-color-step-400: #717171;
//   --ion-color-step-450: #7d7d7d;
//   --ion-color-step-500: #898989;
//   --ion-color-step-550: #949494;
//   --ion-color-step-600: #a0a0a0;
//   --ion-color-step-650: #acacac;
//   --ion-color-step-700: #b8b8b8;
//   --ion-color-step-750: #c4c4c4;
//   --ion-color-step-800: #d0d0d0;
//   --ion-color-step-850: #dbdbdb;
//   --ion-color-step-900: #e7e7e7;
//   --ion-color-step-950: #f3f3f3;
//   --ion-item-background: #1e1e1e;
//   --ion-toolbar-background: #1f1f1f;
//   --ion-tab-bar-background: #1f1f1f;
// }

/* Optional CSS, this is added for the flashing that happens when toggling between themes */
ion-item {
  --transition: none;
}

.line-break {
  white-space: pre-line !important
}

.line-wrap {
  width: 100%;
  float: left;
}

.spin {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.download-fab-button {
  --border-radius: 4px !important;
  width: 120px !important;
  align-self: flex-end;
}

.btn-cursor-pointer .native-input {
  cursor: pointer;
}

.bg-lightblue {
  background-color: #F4F6FF !important;
}

// ion-range {
//   --bar-background: var(--ion-color-light);
//   --bar-background-active: var(--ion-color-primary) !important;
//   --bar-height: 8px;
//   --bar-border-radius: 8px;
//   --knob-background: var(--ion-color-primary) !important;
//   --knob-size: 25px;
// }

.border-top-right-radius-dt {
  border-top-right-radius: 10px;
}

.border-top-left-radius-dt {
  border-top-left-radius: 10px;
}

.border-bottom-right-radius-dt {
  border-bottom-right-radius: 10px;
}

.border-bottom-left-radius-dt {
  border-bottom-left-radius: 10px;
}

.dt-web {
  @apply hidden md:block lg:block xl:block mr-2 ml-2 border-2 b-r-10 bg-[var(--ion-color-white)] border-light-color
}

.dt-mobile {
  @apply mt-2 visible md:hidden lg:hidden xl:hidden h-32 w-full rounded-lg bg-[#282828] flex-col
}

.dt-header {
  @apply bg-[var(--ion-color-grey)] text-center h-14 items-center sticky top-0 z-10 p-2 fw-extra-bold fs-14 border-top-right-radius-dt border-top-left-radius-dt
}

.dt-body {
  @apply bg-[var(--ion-color-white)] text-center items-center border-b p-2 hover:bg-[var(--ion-color-alice-blue)]
}

.filter-1-container {
  @apply mt-3 h-14 rounded-md items-center p-2 flex-row
}

.root {
  @apply pl-10 pr-10
}

.dt-footer {
  @apply bg-[var(--ion-color-white)] flex text-center h-16 flex-row items-center justify-between bottom-0 z-10 px-3 sticky border-top border-light-color border-bottom-right-radius-dt border-bottom-left-radius-dt
}

.dt-active-page {
  @apply flex items-center justify-center w-10 h-10 ml-1 mr-1 bg-[var(--ion-color-grey)] b-r-4
}

.dt-inative-page {
  @apply flex items-center justify-center w-10 h-10 ml-1 mr-1 bg-[var(--ion-color-white)] b-r-4
}

.dt-inactive-text {
  @apply fw-bold fs-16 text-[var(--ion-color-dark)]
}

.dt-active-text {
  @apply fw-bold fs-16 text-[var(--ion-color-dark)]
}

.pagination-button {
  @apply flex items-center justify-center w-10 h-10 ml-1 mr-1 bg-[var(--ion-color-white)] b-r-4
}

.dt-active-page:hover {
  @apply bg-[var(--ion-color-light)] text-[var(--ion-color-white)]
}

.dt-inative-page:hover {
  @apply bg-[var(--ion-color-light)]
}

.pagination-button:hover {
  @apply bg-[var(--ion-color-light)]
}

.icons {
  @apply w-6 h-6
}

.text-success {
  @apply text-[var(--ion-color-success)]
}

.text-pending {
  @apply text-[var(--ion-color-warning)]
}

.text-fail {
  @apply text-[var(--ion-color-danger)]
}

.vertical {
  border-left: 1px solid #d8d6d6;
  height: 58px;
}

.vertical-date {
  border-left: 1px solid #d8d6d6;
  height: 60px;
  margin-top: 1.6rem !important
}

.dt-margin {
  margin-left: 56% !important;
}

.search-box {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.filter-2-container {
  height: 70px;
  width: 100%;
  background-color: var(--ion-color-page-background);
  display: flex;
  align-items: center;
  flex-direction: row;
}

.formClass {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#drop-down {
  background-color: var(--ion-color-pending);
  display: flex;
  border-radius: 32px;
  padding: 3px !important;
}

#drop-down-label {
  opacity: 0.5;
}

.bg-light-background-dt {
  background: var(--ion-color-fusion-theme);
}

#dtinput {
  border: none;
  outline: none;
  width: 20%;
  height: 50px;
}

select {
  font-size: small !important;
}

div.scrollmenu {
  background-color: transparent;
  overflow: auto;
  white-space: nowrap;
}

div.scrollmenu .item {
  display: inline-block;
  text-decoration: none;
  width: 360px;
  margin-right: 20px;
}

div.scrollQuickLinks {
  background-color: transparent;
  overflow: auto;
  white-space: nowrap;
}

div.scrollQuickLinks .item {
  display: inline-block;
  text-decoration: none;
  width: 200px;
  margin-right: 20px;
}

div.scrollQuickLinks .item2 {
  display: inline-block;
  text-decoration: none;
  width: 360px;
  margin-right: 20px;
}

.credit-card-size {
  width: 360px;
  height: 200px;
}

.card-1 {
  color: #fff;
  background: url('../assets/images/backgrounds/card_1.png');
  background-size: cover;
}

.card-2 {
  color: #fff;
  background: url('../assets/images/backgrounds/card_2.png');
  background-size: cover;
}

.card-3 {
  color: #fff;
  background: url('../assets/images/backgrounds/card_3.png');
  background-size: cover;
}

.card-4 {
  color: #fff;
  background: url('../assets/images/backgrounds/card_4.png');
  background-size: cover;
}

.card-5 {
  color: #fff;
  background: url('../assets/images/backgrounds/card_5.png');
  background-size: cover;
}

.card-6 {
  color: #fff;
  background: url('../assets/images/backgrounds/card_6.png');
  background-size: cover;
}

.card-7 {
  color: #fff;
  background: url('../assets/images/backgrounds/card_7.png');
  background-size: cover;
}

.card-8 {
  color: #fff;
  background: url('../assets/images/backgrounds/card_8.png');
  background-size: cover;
}

.card-9 {
  color: #fff;
  background: url('../assets/images/backgrounds/card_9.png');
  background-size: cover;
}

.card-10 {
  color: #fff;
  background: url('../assets/images/backgrounds/card_10.png');
  background-size: cover;
}

.button-outer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  background: var(--ion-color-light);
  border-radius: 100%;
}

.add-new-loan {
  width: 36px;
  height: 36px;
}

.header-option-outer {
  display: flex;
  width: 48px;
  height: 48px;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background-color: rgba(255, 255, 255, .08);
  color: #ffffff;
}

.header-option-outer:hover {
  background-color: rgba(255, 255, 255, .10);
}

.show-unread-notifs {
  position: absolute;
  width: 6px;
  height: 6px;
  background: var(--ion-color-secondary);
  border-radius: 100;
  margin-top: -35px;
  margin-left: 25px;
}

.card-icon {
  width: 48px;
  height: 48px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rec-txn-series {
  list-style: none;
  padding: 0;
}

.rec-txn-series>li {
  border-bottom: 1px dashed var(--ion-color-medium);
  margin-bottom: 10px;
  padding-bottom: 10px;
}

.rec-txn-series>li:last-child {
  border-bottom: 0px dashed var(--ion-color-light);
}

.starter-background {
  background-image: url('../assets/images/backgrounds/starter-bg.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.cursor-pointer-add-underline-dark-bg {
  position: relative;
}

.cursor-pointer-add-underline-dark-bg:hover {
  color: var(--ion-color-white);
}

.cursor-pointer-add-underline-dark-bg:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  bottom: 0;
  left: 0;
  top: 20px;
  background-color: var(--ion-color-warning);
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.3s ease-in-out;
}

.cursor-pointer-add-underline-dark-bg:hover:before {
  visibility: visible;
  transform: scaleX(1);
}

.file-permission-modal {
  &::part(content) {
    position: absolute;
    display: block;
    top: calc(100% - (750px));
    --height: 750px;
    --border-radius: 10px 10px 0px 0px;
    --box-shadow: 0px -0.5px 2px 1px rgba(0, 0, 0, 0.1);
  }

  .modal-shadow {
    position: absolute;
    display: block;
    top: calc(100% - (750px));
    --height: 750px;
  }
}

.ql-card {
  height: auto;
  background: var(--ion-color-white);
}

.ql-card:hover {
  background: var(--ion-color-page-background);
}

.ql-icon {
  width: 32px;
  height: 32px;
}

.bg-ql-card-one {
  background: url('../assets/images/backgrounds/Banner_3.png');
  background-size: cover;
}

.active-info-box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  border-radius: 100%;
  background: var(--ion-color-primary);
}

.active-info-box img {
  width: 32px;
  height: 32px;
}

.inactive-info-box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  border-radius: 100%;
  background: var(--ion-color-paid);
}

.inactive-info-box img {
  width: 32px;
  height: 32px;
}

.complete-info-box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  border-radius: 100%;
  background: var(--ion-color-success);
}

.complete-info-box img {
  width: 32px;
  height: 32px;
}

.draw-border-success {
  transition: color 0.25s 0.0833333333s;
  position: relative;
}

.draw-border-success::before,
.draw-border-success::after {
  border: 0 solid transparent;
  box-sizing: border-box;
  content: "";
  pointer-events: none;
  position: absolute;
  width: 0;
  height: 0;
  bottom: 0;
  right: 0;
  border-radius: 10px;
}

.draw-border-success::before {
  border-bottom-width: 2px;
  border-left-width: 2px;
}

.draw-border-success::after {
  border-top-width: 2px;
  border-right-width: 2px;
}

.draw-border-success:hover {
  border: 2px solid transparent;
}

.draw-border-success:hover::before,
.draw-border-success:hover::after {
  border-color: var(--ion-color-paid-contrast);
  transition: border-color 0s, width 0.25s, height 0.25s;
  width: 100%;
  height: 100%;
}

.draw-border-success:hover::before {
  transition-delay: 0s, 0s, 0.25s;
}

.draw-border-success:hover::after {
  transition-delay: 0s, 0.25s, 0s;
}


.draw-border-failed {
  transition: color 0.25s 0.0833333333s;
  position: relative;
}

.draw-border-failed::before,
.draw-border-failed::after {
  border: 0 solid transparent;
  box-sizing: border-box;
  content: "";
  pointer-events: none;
  position: absolute;
  width: 0;
  height: 0;
  bottom: 0;
  right: 0;
  border-radius: 10px;
}

.draw-border-failed::before {
  border-bottom-width: 2px;
  border-left-width: 2px;
}

.draw-border-failed::after {
  border-top-width: 2px;
  border-right-width: 2px;
}

.draw-border-failed:hover {
  border: 2px solid transparent;
}

.draw-border-failed:hover::before,
.draw-border-failed:hover::after {
  border-color: var(--ion-color-failed-contrast);
  transition: border-color 0s, width 0.25s, height 0.25s;
  width: 100%;
  height: 100%;
}

.draw-border-failed:hover::before {
  transition-delay: 0s, 0s, 0.25s;
}

.draw-border-failed:hover::after {
  transition-delay: 0s, 0.25s, 0s;
}


.draw-border-warning {
  transition: color 0.25s 0.0833333333s;
  position: relative;
}

.draw-border-warning::before,
.draw-border-warning::after {
  border: 0 solid transparent;
  box-sizing: border-box;
  content: "";
  pointer-events: none;
  position: absolute;
  width: 0;
  height: 0;
  bottom: 0;
  right: 0;
  border-radius: 10px;
}

.draw-border-warning::before {
  border-bottom-width: 2px;
  border-left-width: 2px;
}

.draw-border-warning::after {
  border-top-width: 2px;
  border-right-width: 2px;
}

.draw-border-warning:hover {
  border: 2px solid transparent;
}

.draw-border-warning:hover::before,
.draw-border-warning:hover::after {
  border-color: var(--ion-color-warning);
  transition: border-color 0s, width 0.25s, height 0.25s;
  width: 100%;
  height: 100%;
}

.draw-border-warning:hover::before {
  transition-delay: 0s, 0s, 0.25s;
}

.draw-border-warning:hover::after {
  transition-delay: 0s, 0.25s, 0s;
}


.draw-border-pending {
  transition: color 0.25s 0.0833333333s;
  position: relative;
}

.draw-border-pending::before,
.draw-border-pending::after {
  border: 0 solid transparent;
  box-sizing: border-box;
  content: "";
  pointer-events: none;
  position: absolute;
  width: 0;
  height: 0;
  bottom: 0;
  right: 0;
  border-radius: 12px;
}

.draw-border-pending::before {
  border-bottom-width: 2px;
  border-left-width: 2px;
}

.draw-border-pending::after {
  border-top-width: 2px;
  border-right-width: 2px;
}

.draw-border-pending:hover {
  border: 2px solid transparent;
}

.draw-border-pending:hover::before,
.draw-border-pending:hover::after {
  border-color: var(--ion-color-pending-contrast);
  transition: border-color 0s, width 0.25s, height 0.25s;
  width: 100%;
  height: 100%;
}

.draw-border-pending:hover::before {
  transition-delay: 0s, 0s, 0.25s;
}

.draw-border-pending:hover::after {
  transition-delay: 0s, 0.25s, 0s;
}

.draw-border-tertiary {
  transition: color 0.25s 0.0833333333s;
  position: relative;
}

.draw-border-tertiary::before,
.draw-border-tertiary::after {
  border: 0 solid transparent;
  box-sizing: border-box;
  content: "";
  pointer-events: none;
  position: absolute;
  width: 0;
  height: 0;
  bottom: 0;
  right: 0;
  border-radius: 12px;
}

.draw-border-tertiary::before {
  border-bottom-width: 2px;
  border-left-width: 2px;
}

.draw-border-tertiary::after {
  border-top-width: 2px;
  border-right-width: 2px;
}

.draw-border-tertiary:hover {
  border: 2px solid transparent;
}

.draw-border-tertiary:hover::before,
.draw-border-tertiary:hover::after {
  border-color: var(--ion-color-tertiary);
  transition: border-color 0s, width 0.25s, height 0.25s;
  width: 100%;
  height: 100%;
}

.draw-border-tertiary:hover::before {
  transition-delay: 0s, 0s, 0.25s;
}

.draw-border-tertiary:hover::after {
  transition-delay: 0s, 0.25s, 0s;
}


.draw-border-minty-green {
  transition: color 0.25s 0.0833333333s;
  position: relative;
}

.draw-border-minty-green::before,
.draw-border-minty-green::after {
  border: 0 solid transparent;
  box-sizing: border-box;
  content: "";
  pointer-events: none;
  position: absolute;
  width: 0;
  height: 0;
  bottom: 0;
  right: 0;
  border-radius: 10px;
}

.draw-border-minty-green::before {
  border-bottom-width: 2px;
  border-left-width: 2px;
}

.draw-border-minty-green::after {
  border-top-width: 2px;
  border-right-width: 2px;
}

.draw-border-minty-green:hover {
  border: 2px solid transparent;
}

.draw-border-minty-green:hover::before,
.draw-border-minty-green:hover::after {
  border-color: var(--ion-color-minty-green);
  transition: border-color 0s, width 0.25s, height 0.25s;
  width: 100%;
  height: 100%;
}

.draw-border-minty-green:hover::before {
  transition-delay: 0s, 0s, 0.25s;
}

.draw-border-minty-green:hover::after {
  transition-delay: 0s, 0.25s, 0s;
}

.bg-light-background {

  display: flex;
  width: 100%;
  flex-direction: row;
  --inner-padding-start: 0 !important;
  --inner-padding-end: 0 !important;
  background: var(--ion-color-input-box);
  border: 1px solid var(--ion-color-pending);
  border-radius: 0px;
  transition: color 0.25s 0.0833333333s;
  position: relative;

}

.bg-light-background::before,
.bg-light-background::after {
  border: 0 solid transparent;
  box-sizing: border-box;
  content: "";
  pointer-events: none;
  position: absolute;
  width: 0;
  height: 0;
  bottom: 0;
  right: 0;
  border-radius: 0px;
}

.bg-light-background::before {
  border-bottom-width: 1px;
  border-left-width: 1px;
}

.bg-light-background::after {
  border-top-width: 1px;
  border-right-width: 1px;
}

.bg-light-background:focus-within {
  border: 1px solid transparent;
}

.bg-light-background:focus-within::before,
.bg-light-background:focus-within::after {
  border-color: var(--ion-color-pending-contrast);
  transition: border-color 0s, width 0.25s, height 0.25s;
  width: 100%;
  height: 100%;
}

.bg-light-background:focus-within::before {
  transition-delay: 0s, 0s, 0.25s;
}

.bg-light-background:focus-within::after {
  transition-delay: 0s, 0.25s, 0s;
}


.credit-banner img {
  border-radius: 10px;
  width: 100%;
}

.dash-ico-out {
  display: flex;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background: var(--ion-color-very-light);
  align-items: center;
  justify-content: center;
}

.bg-viccas-gradient {
  background: linear-gradient(45deg, var(--ion-color-primary), var(--ion-color-secondary));
}

.go-premium-banner {

  background: #23074d;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #cc5333, #23074d);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #cc5333, #23074d);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  color: #ffffff;

  ul {
    list-style-type: none;
    /*use padding to move list item from left to right*/
    padding-left: 1em;
  }

  ul li:before {
    content: "–";
    position: absolute;
    /*change margin to move dash around*/
    margin-left: -1em;
  }
}

.dashboard-bank-icon {

  width: 24px;
  height: 24px;
  border-radius: 50%;

}

div.scrollChipMenu {
  background-color: transparent;
  overflow: auto;
  white-space: nowrap;
}

div.scrollChipMenu ion-chip {
  min-width: 160px;
  justify-content: center;
}

div.scrollChipMenu .item {
  display: inline-block;
  text-decoration: none;
  width: 150px;
  margin-right: 20px;
}

ion-range::part(pin) {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transform: scale(1.01);
  top: -20px;
  transition: transform 120ms ease, background 120ms ease;
}

.invoice-box {
  position: relative;
  box-shadow: 0 0 15px rgba(0, 0, 0, .1);
}

/* Ribbon */
.ribbon {
  width: 100px;
  height: 100px;
  overflow: hidden;
  position: absolute;
}

.ribbon::before,
.ribbon::after {
  position: absolute;
  z-index: -1;
  content: '';
  display: block;
}

.ribbon span {
  position: absolute;
  display: block;
  width: 225px;
  padding: 2px 0;
  box-shadow: 0 5px 10px rgba(0, 0, 0, .1);
  font: 600 16px/1 'Lato';
  color: #fff;
  text-shadow: 0 1px 1px rgba(0, 0, 0, .2);
  text-align: center;
  margin-right: -20px;
}

.ribbon-pending::before,
.ribbon-pending::after {
  border: 5px solid var(--ion-color-primary);
}

.ribbon-pending span {
  background-color: var(--ion-color-primary);
}

.ribbon-paid::before,
.ribbon-paid::after {
  border: 5px solid var(--ion-color-success);
}

.ribbon-paid span {
  background-color: var(--ion-color-success);
}

.ribbon-partial-paid::before,
.ribbon-partial-paid::after {
  border: 5px solid var(--ion-color-warning);
}

.ribbon-partial-paid span {
  background-color: var(--ion-color-warning);
}

.ribbon-cancelled::before,
.ribbon-cancelled::after {
  border: 5px solid var(--ion-color-danger);
}

.ribbon-cancelled span {
  background-color: var(--ion-color-danger);
}

.ribbon-draft::before,
.ribbon-draft::after {
  border: 5px solid var(--ion-color-medium);
}

.ribbon-draft span {
  background-color: var(--ion-color-medium);
}

/* top left*/
.ribbon-top-left {
  top: 0px;
  left: 0px;
}

.ribbon-top-left::before,
.ribbon-top-left::after {
  border-top-color: transparent;
  border-left-color: transparent;
}

.ribbon-top-left::before {
  top: 0;
  right: 45px;
}

.ribbon-top-left::after {
  bottom: 35px;
  left: 0;
}

.ribbon-top-left span {
  right: -25px;
  top: 30px;
  transform: rotate(-45deg);
}

.file-input-wrapper {
  width: 100%;
  height: 40px;
  overflow: hidden;
  position: relative;
  text-align: center;
  display: flex;
  justify-content: center;
}

.file-input-wrapper>ion-input[type="file"] {
  font-size: 20px;
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0;
}

.file-input-wrapper>.btn-file-input {
  display: inline-block;
  width: 100%;
  height: 30px;
}

.date-filter{
  background-color: var(--ion-color-white);
  height: 44px !important;
  width: 130px;
  border-radius: 4px;
  padding: 2px;
  cursor: pointer;
}

.notification-popover {
  --width: 290px !important;
    overflow-y: auto;
}

